import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';
import moment from 'moment';
import pdfimage from '../../assets/pdfimage.png';
import ppticon from '../../assets/ppticon.png';
import docicon from '../../assets/docImage.png';
import exlicon from '../../assets/exlImage.png';
import vedioIcon from '../../assets/vedioicon.png';

import { ExternalLink, IconCard } from '../../components';

const SectionDocumentsMaybe = props => {
  const { documents, isBrief, listing, currentUser } = props;
  const price = listing?.id && listing?.attributes?.publicData;
  const phoneNumber = currentUser?.attributes.profile?.protectedData.phoneNumber;
  const data = listing?.author?.attributes.profile?.publicData;
  const date = listing?.author?.attributes.createdAt;
  const createDate = moment(date).format('LLL');
  
  const downloadFiles = file => {
    fetch(file).then(response => {
      response.blob().then(blob => {
        let url = typeof window !== 'undefined' && window.URL.createObjectURL(blob);
        let a = typeof window !== 'undefined' && window.document.createElement('a');
        a.href = url;
        a.download = file.substring(file.lastIndexOf('/') + 1);
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  function formatFileSize(sizeInBytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    const size = (sizeInBytes / Math.pow(1024, i)).toFixed(1);
    return `${size} ${units[i]}`;
  }


  return (
    <div>
      <h2 className={css.documentsHeading}>
        {isBrief ? (
          <FormattedMessage id="SectionDocumentsBriefDocuments.headingClient" />
        ) : (
          <FormattedMessage id="SectionDocumentsBriefDocuments.heading" />
        )}
      </h2>
      {documents &&
        documents.map((doc, i) => {
          const fileTypeExt = doc.name.split('.')[1];

          if (isBrief) {
            return (
              <div key={i} className={css.documentsWrapper}>
                <div className={css.documentImage}>
                  {/* <img src={docicon} /> */}
                  {fileTypeExt?.includes("pdf") ? (
                    <img src={pdfimage} />
                  ) : ['doc', 'docs', 'docx'].includes(fileTypeExt) ? (
                    <img src={docicon} />
                  ) : ['exl', 'xlsx'].includes(fileTypeExt) ? (
                    <img src={exlicon} />
                  ) : fileTypeExt?.includes('ppt', 'pptx') ? (
                    <img src={ppticon} />
                  ) : ['csv'].includes(fileTypeExt) ? (
                    <IconCard brand="csv" />
                  ) : ['zip'].includes(fileTypeExt) ? (
                    <IconCard brand="zip" />
                  ) : null}
                </div>
                <div className={css.documentRightBox}>
                  <div className={css.documentName}>{doc && doc.name}</div>
                  <div className={css.documentDate}>
                    {doc && moment(doc.date).format("Do MMM YYYY")} at {moment(doc.date).format("LT")}
                    <span className={css.fileSize}>
                      {doc?.size ? formatFileSize(doc?.size) : null}
                    </span>
                  </div>
                </div>
              </div>
            )
          } else {
            return (
              <div className={css.mediaUpload} key={i}>
                {/\mp4|MP4|mov|webm/.test(doc.link) ? (
                  // <video src={doc.link} loop controls muted className={css.documentUpload} />
                  <div className={css.documentUpload}>
                    <div className={css.mediaObject}>
                      <div className={css.documentWrapper}>
                        <div className={css.documentTypeIcon}>
                          {fileTypeExt?.includes("mp4") ? (
                            <img src={vedioIcon} />
                          ) : null}
                        </div>
                        <div className={css.docRightBox}>
                          <div className={css.docName}>{doc.name}</div>
                          <div className={css.docDate}>{doc.date}</div>
                        </div>
                      </div>
                      <div className={css.hoverView}>
                        <div className={css.documentFullScreen}>
                          <div className={css.documentTypeIcon}>
                            {fileTypeExt?.includes("mp4") ? (
                              "VIDEO"
                            ) : null}
                          </div>
                          <div className={css.documentNameDetail}>
                            {/* <div>{doc.name}</div> */}
                            {doc?.size ? <div className={css.fileSize}>{formatFileSize(doc?.size)}</div> : null}
                          </div>
                        </div>
                        <div className={css.documentNavIcon}>
                          <span onClick={() => downloadFiles(doc.link)}>
                            <IconCard brand="pdfdownload" />
                          </span>
                          <span>
                            <ExternalLink href={['pptx', 'ppt'].includes(fileTypeExt) ? `https://view.officeapps.live.com/op/embed.aspx?src=${doc.link}` : doc?.link} target="blank">
                              <IconCard brand="natigate" />
                            </ExternalLink>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={css.documentUpload}>
                    <div className={css.mediaObject}>                       
                      <div className={css.documentWrapper}>
                        <div className={css.documentTypeIcon}>
                          {fileTypeExt?.includes("pdf") ? (
                            <img src={pdfimage} />
                          ) : ['doc', 'docs', 'docx'].includes(fileTypeExt) ? (
                            <img src={docicon} />
                          ) : ['exl', 'xlsx'].includes(fileTypeExt) ? (
                            <img src={exlicon} />
                          ) : fileTypeExt?.includes('ppt', 'pptx') ? (
                            <img src={ppticon} />
                          ) : ['csv'].includes(fileTypeExt) ? (
                            <IconCard brand="csv" />
                          ) : ['zip'].includes(fileTypeExt) ? (
                            <IconCard brand="zip" />
                          ) : null}
                        </div>
                        <div className={css.docRightBox}>
                          <div className={css.docName}>{doc.name}</div>
                          <div className={css.docDate}>{doc.date}</div>
                        </div>
                      </div>
                      <div className={css.hoverView}>
                        <div className={css.documentFullScreen}>
                          <div className={css.documentTypeIcon}>
                            {fileTypeExt?.includes("pdf") ? (
                              "PDF"
                            ) : ['doc', 'docs', 'docx'].includes(fileTypeExt) ? (
                              "DOC"
                            ) : ['exl', 'xlsx'].includes(fileTypeExt) ? (
                              "EXCEL"
                            ) : fileTypeExt?.includes('ppt', 'pptx') ? (
                              "PPT"
                            ) : ['csv'].includes(fileTypeExt) ? (
                              "CSV"
                            ) : ['zip'].includes(fileTypeExt) ? (
                              "ZIP"
                            ) : null}
                          </div>
                          <div className={css.documentNameDetail}>
                            {/* <div>{doc.name}</div> */}
                            {doc?.size ? <div className={css.fileSize}>{formatFileSize(doc?.size)}</div> : null}
                          </div>
                        </div>
                        <div className={css.documentNavIcon}>
                          <span onClick={() => downloadFiles(doc.link)}>
                            <IconCard brand="pdfdownload" />
                          </span>
                          <span>
                            <ExternalLink href={['pptx', 'ppt'].includes(fileTypeExt) ? `https://view.officeapps.live.com/op/embed.aspx?src=${doc.link}` : doc?.link} target="blank">
                              <IconCard brand="natigate" />
                            </ExternalLink>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          }
        }
        )
      }
    </div>
  );
};

export default SectionDocumentsMaybe;
