import pick from 'lodash/pick';
import config from '../../config';
import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  transactionLineItems,
  getAccessToken,
  scheduleGoogleMeeting,
  getAllTransactions,
  createZoomToken,
  getZakCode,
  createMeeting,
  createUserSignature,
  updateUserProfile,
  updateFirmUserDetails,
  storeNotification,
  updateListingPublicData,
  fetchTag,
  handleWeavaiteDatabase,
} from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, nextMonthFn, monthIdStringInTimeZone } from '../../util/dates';
import {
  TRANSITION_BRIEF_CREATE_PROPOSAL,
  TRANSITION_OFFER_BRIEF,
  TRANSITION_SEND_JOB_APPLICATION,
  TRANSITION_SEND_JOB_DESCRIPTION,
} from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { initiatePrivileged } from '../../util/api';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import moment from 'moment';
import { getFirmId, getInStripeAccountId, getUserDetails } from '../../util/destructorHelpers';
import {
  INVITE_STATUS_ACTIVE,
  JOB_NOTIFICATION,
  PRIVATELY_SHARED_PROJECT,
  SCHEDULE_MEETING_NOTIFICATION,
  SEND_PUBLIC_PROPOSAL,
  TRANSACTION_NOTIFICATION,
  USER_ROLE_CLIENT,
  USER_ROLE_CSM,
  USER_ROLE_PARTNER,
} from '../../util/types';
import { fetchUserRole, getUserRole } from '../../util/userRole';

const { UUID } = sdkTypes;

const currentMonth = moment().month() + 1;
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const CREATE_MEETING_REQUEST = 'app/ListingPage/CREATE_MEETING_REQUEST';
export const CREATE_MEETING_SUCCESS = 'app/ListingPage/CREATE_MEETING_SUCCESS';
export const CREATE_MEETING_ERROR = 'app/ListingPage/CREATE_MEETING_ERROR';
export const CLEAR_MEET_SUCCESS_TOASTER = 'app/ListingPage/CLEAR_MEET_SUCCESS_TOASTER';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const FETCH_TRANSACTION_REQUEST = 'app/ListingPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/ListingPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/ListingPage/FETCH_TRANSACTION_ERROR';

export const INIT_PROPOSAL_REQUEST = 'app/ListingPage/INIT_PROPOSAL_REQUEST';
export const INIT_PROPOSAL_SUCCESS = 'app/ListingPage/INIT_PROPOSAL_SUCCESS';
export const INIT_PROPOSAL_ERROR = 'app/ListingPage/INIT_PROPOSAL_ERROR';

export const ZOOM_MEETING_REQUEST = 'app/TransactionPage/ZOOM_MEETING_REQUEST';
export const ZOOM_MEETING_SUCCESS = 'app/TransactionPage/ZOOM_MEETING_SUCCESS';
export const ZOOM_MEETING_ERROR = 'app/TransactionPage/ZOOM_MEETING_ERROR';
export const ZOOM_MEETING_CREATED = 'app/TransactionPage/ZOOM_MEETING_CREATED';

export const FETCH_FIRM_LISTING = 'app/LandingPage/FETCH_FIRM_LISTING';
export const FETCH_TAGS = 'app/LandingPage/FETCH_TAGS';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  uploadInProgress: false,
  documents: null,
  initProposalInProgress: false,
  initProposalError: null,
  documentsProposal: [],
  meetingInProgress: false,
  meetingError: null,
  meetingCreated: false,
  transactionRef: null,
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactions: [],
  zoomMeetingInProgress: false,
  zoomMeetingError: false,
  zoomMeetingCreated: false,
  firmListing: {},
  tags: [],
  loadingListing: false,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null, loadingListing: true };
    case SHOW_LISTING_SUCCESS:
      return { ...state, id: payload.id, showListingError: null, loadingListing: false };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload, loadingListing: false };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case INIT_PROPOSAL_REQUEST:
      return { ...state, initProposalInProgress: true, initProposalError: null };
    case INIT_PROPOSAL_SUCCESS:
      return { ...state, initProposalInProgress: false };
    case INIT_PROPOSAL_ERROR:
      return { ...state, initProposalInProgress: false, initProposalError: payload };
    case CREATE_MEETING_REQUEST:
      return { ...state, meetingInProgress: true, meetingCreated: false, meetingError: null };
    case CREATE_MEETING_SUCCESS:
      return { ...state, meetingInProgress: false, meetingCreated: true, meetingError: null };
    case CREATE_MEETING_ERROR:
      return { ...state, meetingInProgress: false, meetingCreated: false, meetingError: payload };
    case CLEAR_MEET_SUCCESS_TOASTER:
      return { ...state, meetingInProgress: false, meetingCreated: false, meetingError: null };
    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      return { ...state, fetchTransactionInProgress: false, transactions: payload };
    }
    case FETCH_TRANSACTION_ERROR:
      // console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };
    case ZOOM_MEETING_REQUEST:
      return { ...state, zoomMeetingInProgress: true, zoomMeetingError: null };
    case ZOOM_MEETING_SUCCESS:
      return { ...state, zoomMeetingInProgress: false, zoomMeetingError: null };
    case ZOOM_MEETING_ERROR:
      return { ...state, zoomMeetingInProgress: false, zoomMeetingError: true };
    case ZOOM_MEETING_CREATED:
      return {
        ...state,
        zoomMeetingInProgress: false,
        zoomMeetingError: false,
        zoomMeetingCreated: true,
      };
    case FETCH_FIRM_LISTING:
      return { ...state, firmListing: payload };
    case FETCH_TAGS:
      return { ...state, tags: payload };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingSuccess = id => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const initProposalRequest = () => ({ type: INIT_PROPOSAL_REQUEST });
export const initProposalSuccess = () => ({ type: INIT_PROPOSAL_SUCCESS });
export const initProposalError = e => ({ type: INIT_PROPOSAL_ERROR, error: true, payload: e });

const zoomMeetingRequest = () => ({ type: ZOOM_MEETING_REQUEST });
const zoomMeetingSuccess = () => ({ type: ZOOM_MEETING_SUCCESS });
const zoomMeetingCreated = () => ({ type: ZOOM_MEETING_CREATED });
const zoomMeetingError = () => ({ type: ZOOM_MEETING_ERROR });

export const createMeetingRequest = () => ({
  type: CREATE_MEETING_REQUEST,
});
export const clearMeetingSuccessToaster = () => ({
  type: CLEAR_MEET_SUCCESS_TOASTER,
});

export const createMeetingSuccess = () => ({
  type: CREATE_MEETING_SUCCESS,
});

export const createMeetingError = e => ({
  type: CREATE_MEETING_ERROR,
  error: true,
  payload: e,
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

export const fetchFirmListing = data => ({
  type: FETCH_FIRM_LISTING,
  payload: data,
});

export const fetchTagsSuccess = (data) => ({
  type: FETCH_TAGS,
  payload: data
})
// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  try {
    dispatch(showListingRequest(listingId));
    const currentUser = getState().user.currentUser
    const params = {
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    };

    const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

    return show
      .then(async (data) => {
        let result = {...data};
        let listingType = data?.data?.data?.attributes?.publicData?.listingType

        if(listingType === USER_ROLE_PARTNER){
          try {
            let response = await handleWeavaiteDatabase({
              actionType: 'getFirmDetails',
              details: { id: listingId?.uuid }
            });
            if(response?.response?.id){
              result.data.data.attributes.publicData.firmLogo = response.response.properties.firmLogo;
            }
          } catch (error) {
            console.log("Error fetching firm details:", error);
          }
        }
        dispatch(addMarketplaceEntities(result));
        dispatch(showListingSuccess(listingId));
        return result;
      })
      .catch(e => {
        dispatch(showListingError(storableError(e)));
      });
  } catch (e) {
    // console.log(e.message)
    dispatch(showListingError(e));
  }
};

export const fetchReviews = currentUserId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      subjectId: currentUserId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  try {
    const monthId = monthIdStringInTimeZone(start, timeZone);

    dispatch(fetchTimeSlotsRequest(monthId));

    // The maximum pagination page size for timeSlots is 500
    const extraParams = {
      per_page: 500,
      page: 1,
    };

    return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
      .then(timeSlots => {
        dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
      })
      .catch(e => {
        dispatch(fetchTimeSlotsError(monthId, storableError(e)));
      });
  } catch (e) {
    // console.log(e)
    dispatch(fetchTimeSlotsError(monthId, e));
  }
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  // const bodyParams = {
  //   transition: TRANSITION_ENQUIRE,
  //   processAlias: config.bookingProcessAlias,
  //   params: { listingId },
  // };
  // return sdk.transactions
  //   .initiate(bodyParams)
  //   .then(response => {
  //     const transactionId = response.data.data.id;

  //     // Send the message to the created transaction
  //     return sdk.messages.send({ transactionId, content: message }).then(() => {
  //       dispatch(sendEnquirySuccess());
  //       dispatch(fetchCurrentUserHasOrdersSuccess(true));
  //       return transactionId;
  //     });
  //   })
  //   .catch(e => {
  //     dispatch(sendEnquiryError(storableError(e)));
  //     throw e;
  //   });
};

const storeNotificationInDb = async params => {
  try {
    const response = await storeNotification(params);
  } catch (error) {
    return;
  }
};
export const initProposal = params => (dispatch, getState, sdk) => {
  const { userEmail, briefId, userName } = params;
  dispatch(initProposalRequest());
  try {
    const { currentAuthor, currentUser } = params;
    const briefCountDetails = currentUser?.id && getUserDetails(currentUser)?.briefCountDetails || {};
    const { privateBriefSentCount = null, briefSentMonth } = briefCountDetails ?? {}
    const updatedBriefCountDetails = {
      ...briefCountDetails,
      privateBriefSentCount: privateBriefSentCount + 1,
      briefSentMonth: briefSentMonth ?? currentMonth
    };

    const profile = {
      publicData: {
        briefCountDetails: updatedBriefCountDetails
      }
    }
    const isAuthorIndianOrigin = currentAuthor?.id && !!getInStripeAccountId(currentAuthor);
    const bodyParams = {
      transition: TRANSITION_OFFER_BRIEF,
      // processAlias: config.prepaymentProcessIndia,
      processAlias: isAuthorIndianOrigin
        ? config.prepaymentProcessIndia
        : config.proposalProcessAlias,
      params,
    };
    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };
    return initiatePrivileged({ bodyParams, queryParams })
      .then(response => {
        dispatch(initProposalSuccess());
        dispatch(updateProfile(profile));
        updateFirmUserDetails({ userEmail, action: PRIVATELY_SHARED_PROJECT, listingId: briefId });
        const transactionId = response.data.data.id;
        if (transactionId) {
          const notificationObject = {
            userId: currentAuthor?.id?.uuid,
            notificationType: TRANSACTION_NOTIFICATION,
            content: `${userName} has sent you a brief.`,
            transactionId: transactionId.uuid,
            notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${transactionId.uuid}/details`,
          };
          storeNotificationInDb(notificationObject);
        }

        return transactionId;
      })
      .catch(e => {
        dispatch(initProposalError(storableError(e)));
      });
  } catch (e) {
    // console.log(e)
    dispatch(initProposalError(e));
  }
};

export const initBriefProposal = params => async (dispatch, getState, sdk) => {
  const { userEmail, listingId, userName, authorId, proposalReceivedCount = 0 } = params;
  const updateCount = () => proposalReceivedCount + 1;
  dispatch(initProposalRequest());
  try {
   
    const bodyParams = {
      transition: TRANSITION_BRIEF_CREATE_PROPOSAL,
      processAlias: config.briefProcessAlias,
      params,
    };
    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };
    return initiatePrivileged({ bodyParams, queryParams })
      .then(async response => {
        const transactionId = response.data.data.id || null;

        if (transactionId) {
          const notificationObject = {
            userId: authorId,
            notificationType: TRANSACTION_NOTIFICATION,
            content: `You have received a project proposal from ${userName}.`,
            transactionId: transactionId.uuid,
            notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${transactionId.uuid}/details`,
          };
          storeNotificationInDb(notificationObject);
          updateListingPublicData({
            id: listingId,
            publicData: {
              proposalReceivedCount: updateCount()
            }
          })
        }
        dispatch(initProposalSuccess());
        updateFirmUserDetails({ userEmail, action: SEND_PUBLIC_PROPOSAL });

        return transactionId;
      })
      .catch(e => {
        dispatch(initProposalError(storableError(e)));
      });
  } catch (e) {
    dispatch(initProposalError(e));
  }
};

export const initApplyJob = params => async (dispatch, getState, sdk) => {
  dispatch(initProposalRequest());
  const { currentUser, currentAuthor, ...filteredParams } = params;
  const customerName = currentUser && currentUser.id ? getUserDetails(currentUser).fullName : null;
  const authorId = currentAuthor && currentAuthor.id ? getUserDetails(currentAuthor).id : null;

  try {
    const bodyParams = {
      transition: TRANSITION_SEND_JOB_APPLICATION,
      processAlias: config.applyJobProcess,
      params: filteredParams,
    };
    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };
    const response = await initiatePrivileged({ bodyParams, queryParams });
    dispatch(initProposalSuccess());
    const transactionId = response.data.data.id;

    return transactionId;
  } catch (e) {
    dispatch(initProposalError(storableError(e)));
  }
};

export const initSendJobDescription = params => async (dispatch, getState, sdk) => {
  dispatch(initProposalRequest());
  try {
    const { currentUser = {}, currentAuthor = {}, ...filteredParams } = params || {}
    const authorId = currentAuthor.id.uuid;
    const userName = currentUser?.id && getUserDetails(currentUser)?.fullName;
    const bodyParams = {
      transition: TRANSITION_SEND_JOB_DESCRIPTION,
      processAlias: config.jobDescriptionProcess,
      params: filteredParams,
    };
    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };
    const response = await initiatePrivileged({ bodyParams, queryParams });

    dispatch(initProposalSuccess());
    const transactionId = response.data.data.id;
    if (transactionId) {
      const notificationObject = {
        userId: authorId,
        notificationType: JOB_NOTIFICATION,
        content: `You received a JD from ${userName}.`,
        transactionId: transactionId.uuid,
        notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${transactionId.uuid}/details`,
      };
      storeNotificationInDb(notificationObject);
    }
    return transactionId;
  } catch (e) {
    dispatch(initProposalError(storableError(e)));
  }
};

export const getGoogleAccessToken = () => async (dispatch, getState, sdk) => {
  try {
    const getToken = await getAccessToken({});
    window.location.href = getToken;
  } catch (error) {
    // console.error(error,'error')
  }
};
export const scheduleGoogleMeet = params => async (dispatch, getState, sdk) => {
  dispatch(createMeetingRequest());
  const { currentUser = {}, currentTransaction = {}, isTransactionPage, ...filteredParams } = params;
  const { startDateTime, endDateTime, emails } = filteredParams;
  const { customer = {}, provider = {} } = currentTransaction || {};
  try {
    if (isTransactionPage) {

      const txId = currentTransaction?.id?.uuid;
      const providerId = provider?.id && getUserDetails(provider).id;
      const userEmail = currentUser?.id && getUserDetails(currentUser).email;
      const userName = currentUser?.id && getUserDetails(currentUser).fullName;
      const { collaborationMetaData = [] } =
        (currentTransaction.id && currentTransaction.attributes.metadata) || {};
      const activeCollaborators = collaborationMetaData.filter(
        ({ email, status }) => email !== userEmail && status === INVITE_STATUS_ACTIVE
      );
      // Calculate duration in minutes
      const durationInMinutes = Math.floor((new Date(endDateTime) - new Date(startDateTime)) / 1000 / 60);

      // Format date and time strings
      const dateString = new Date(startDateTime).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const timeString = new Date(startDateTime).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
      });
      const notificationObject = {
        userId: providerId,
        notificationType: SCHEDULE_MEETING_NOTIFICATION,
        content: `${userName} has scheduled a meeting on ${dateString} at ${timeString} for ${durationInMinutes} minutes via Google Meet. Please check your calendar.`,
        transactionId: txId,
        notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
      };

      const recipientObjs = [
        {
          collaboratorID: getUserDetails(customer)?.id,
          email: getUserDetails(customer)?.email
        },
        {
          collaboratorID: getUserDetails(provider)?.id,
          email: getUserDetails(provider)?.email
        },
      ]
      const recipientIds = [
        ...recipientObjs,
        ...(activeCollaborators || []),
      ].filter(item => item.email && emails.some(emailObj => emailObj.email === item.email)).map(({ collaboratorID }) => collaboratorID);

      for (const userId of recipientIds) {
        // Send notification with user-specific content
        await storeNotificationInDb({
          ...notificationObject,
          userId,
        });
      }
    }

    const scheduleMeeting = await scheduleGoogleMeeting(isTransactionPage ? filteredParams : params);
    if (scheduleMeeting) {
      dispatch(createMeetingSuccess());
    }
    return scheduleMeeting;
  } catch (error) {
    // console.log(error)
    dispatch(createMeetingError(storableError(error)));
  }
};
// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  try {
    const hasWindow = typeof window !== 'undefined';
    const attributes = listing.attributes;
    // Listing could be ownListing entity too, so we just check if attributes key exists
    const hasTimeZone =
      attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

    // Fetch time-zones on client side only.
    if (hasWindow && listing.id && hasTimeZone) {
      const tz = listing.attributes.availabilityPlan.timezone;
      const nextBoundary = findNextBoundary(tz, new Date());

      const nextMonth = nextMonthFn(nextBoundary, tz);
      const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

      return Promise.all([
        dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
        dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
      ]);
    }

    // By default return an empty array
    return Promise.all([]);
  } catch (e) {
    // console.log(e)
    return;
  }
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};
export const fetchTransaction = id => async (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  try {
    const transaction = !!id && (await getAllTransactions({ userId: id }));
    dispatch(fetchTransactionSuccess(transaction));
    return transaction;
  } catch (error) {
    dispatch(fetchTransactionError(storableError(error)));
    // throw error;
  }
};

export const createZoomAuth = params => async (dispatch, getState, sdk) => {
  const { code, listingId, userSlug } = params;

  if (code && listingId && userSlug) {
    const response = await createZoomToken({ code, listingId, userSlug });
    if (response.message === 'Success') dispatch(fetchCurrentUser());
  }
};

// export const retrieveZak = params => (dispatch, getState, sdk) => {
//   const { zoomUserId, accessToken, refreshToken, meetingID, isMeetingHost, isTokenExpired, currentUserId } = params;
//   return getZakCode({ zoomUserId, accessToken, refreshToken, meetingID, isMeetingHost, isTokenExpired, currentUserId })
//     .then((response) => {
//       const { zoomUserDataZak, signature } = response;
//       const zak = zoomUserDataZak && zoomUserDataZak.token;
//       return { zak, signature }
//     })
//     .catch(e => console.log(e, 'error'))
// };

// export const retrieveSignature = params => (dispatch, getState, sdk) => {
//   const { meetingID, isMeetingHost } = params;
//   return createUserSignature({ meetingID, isMeetingHost })
//     .then((response) => {
//       const { signature } = response;
//       return signature
//     })
//     .catch(e => console.log(e, 'error'))
// };

export const createZoomMeeting = params => async (dispatch, getState, sdk) => {
  // const { email, accessToken, refreshToken, txId, isTokenExpired, currentUserId } = params;
  dispatch(zoomMeetingRequest());
  try {
    const meeting = await createMeeting({ ...params });
    if (meeting?.status === 'success') dispatch(zoomMeetingCreated());
    else dispatch(zoomMeetingError());

    return meeting;
  } catch (e) {
    // console.log(e)
    dispatch(zoomMeetingError());
  }
};

export const getListing = ids => async (dispatch, getState, sdk) => {
  try {
    const response = await sdk.listings.query({
      ids: ids,
      include: ['author', 'author.profileImage', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'description', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 1,
    });

    dispatch(addMarketplaceEntities(response));
    const state = getState();

    return state
  } catch (error) {
    // console.log(error)
    throw error;
  }
};

export const updateUserProfileThroughIntegrationSdk = profile => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    const updatedProfile = await updateUserProfile({ data: profile });
    dispatch(fetchCurrentUser());
  } catch (error) {
    // console.error(error)
  }
};

export const fetchFirmListings = firmId => async (dispatch, getState, sdk) => {
  try {
    const { data: { data = {} } = {} } = await sdk.listings.show({
      id: new UUID(firmId),
    });
    dispatch(fetchFirmListing(data));
  } catch (error) { }
};

export const loadData = (params, search) => async (dispatch, getState) => {
  try {
    const listingId = new UUID(params.id);
    dispatch(showListingRequest(listingId));
    const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];

    fetchTag().then(response => {
      const {tags} = response || {};
      const modifiedTags = tags?.length ? [...new Set(tags.map(tag => tag.toLowerCase()))] : tags;
      dispatch(fetchTagsSuccess(modifiedTags))
    })

    if (ownListingVariants.includes(params.variant)) {
      return dispatch(showListing(listingId, true));
    }

    await dispatch(fetchCurrentUser());
    const currentUser = getState().user?.currentUser;
    const isCsm = currentUser?.id && getUserRole(currentUser) === USER_ROLE_CSM;
    const linkedFirms = currentUser?.attributes?.profile?.publicData?.linkedToFirms || [];
    const firmId =
      !!currentUser?.id &&
      ((Array.isArray(linkedFirms) && linkedFirms[0]?.firmId) || getFirmId(currentUser));

    if (firmId) {
      dispatch(fetchFirmListings(firmId));
    }
    return Promise.all([dispatch(showListing(listingId))]).then(responses => {
      if (responses[0] && responses[0].data && responses[0].data.data) {
        const listing = responses[0].data?.data;
        const authorId = listing?.relationships?.author?.data?.id;
        const isOwnListing = currentUser?.id.uuid === authorId?.uuid;

        if (authorId?.uuid) {
          dispatch(fetchReviews(authorId));
          dispatch(fetchTransaction(authorId?.uuid));
        }
        // Fetch timeSlots.
        // This can happen parallel to loadData.
        // We are not interested to return them from loadData call.
        // fetchMonthlyTimeSlots(dispatch, listing);
      }
      return responses;
    });
  } catch (e) {
    // console.log(e)
    dispatch(fetchTransactionError(e));
    return;
  }
};
