import React from 'react'
import { AI_BRIEF_GENERATOR, AI_COMBINED_TOPIC_SENTIMENT, AI_CONTENT_ANALYSIS, AI_CONTRACT, AI_DISCUSSION_GUIDE, AI_JOB_DESCRIPTION, AI_KEYWORD_EXTRACTION, AI_PERFECT_QUESTION, AI_PRE_TEXT_PROCESSING, AI_PROPOSAL, AI_QUERY_REPORT, AI_QUESTIONNAIRE, AI_ANALYZE_QUALITATIVE_DATA, AI_RESEARCH_INSTRUMENT_COVERAGE_INDICATOR, AI_SENTIMENT_ANALYSIS, AI_SUGGEST_ANSWER_OPTION, AI_TEXTUAL_CONCEPT_CREATER, AI_TOPIC_MODELING, AI_TRANSCRIPTION, AI_ANALYZE_RAW_QUANTITATIVE_DATA, AI_RESEARCH_INSTRUMENT_TRANSLATOR, AI_OPEN_ENDED_RESPONSE_TRANSLATOR, AI_TRANSLATE_TRANSCRIPT, AI_EXTRACT_QUESTIONS_FROM_TRANSCRIPTS, AI_ANALYZE_UNSTRUCTURED_DATA } from '../../util/types';
import css from './OpenAIAppsPanel.module.css'

const OpenAIFeaturesPanel = (props) => {
	const { appRoute } = props;
	const handleFeatures = () => {
		switch (appRoute) {
			case AI_BRIEF_GENERATOR:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Generates detailed brief with just a few lines of input.</li>
							<li>Gives you the opportunity to provide additional inputs such as target customers for interviews, sample size,
								centers for primary interviews, etc.</li>
							<li>Allows you to specify the timeline and budget you have in mind.</li>
							<li>Allows you to attach a supporting document that you might have. For instance, you could have the table of
								contents of the brief that you wish to develop, and the app automatically recognizes and populates it!</li>
						</ul>
					</div>
				);
			case AI_QUESTIONNAIRE:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Generates a questionnaire based on inputs given by you such as research objective, target respondents,
								questionnaire length, etc.</li>
							<li>Provides the ability to give input on the tone of questionnaire; such as friendly, to-the-point, etc.</li>
							<li>Allows you to choose between a completely quantitative questionnaire OR a primarily quantitative
								questionnaire with some open-ended questions.</li>
						</ul>
					</div>
				);
			case AI_TRANSCRIPTION:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Attach files and get transcribed output in seconds or minutes!</li>
							<li>Provide specific instructions such as difficult words or anything else the app needs to look for in the
								audio.</li>
						</ul>

					</div>
				);
			case AI_CONTRACT:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Generate different types of documents such as NDA or a Services Procurement Contract.</li>
							<li>Add specific instructions such as a clause you require, the names of the signatories, their companies,
								designation, etc.</li>
							<li>Ability to add Zoom signature fields automatically for use on the InsightGig platform.</li>
							<li>Ability to enter the start date and end date of the contract.</li>
							<li>Ability to attach supporting documents to be used as input for generating the contract. For instance, you
								could use the final proposal from the vendor as a supporting document. The app will automatically pull in
								details mentioned in the proposal!</li>
						</ul>
					</div>
				);
			case AI_PROPOSAL:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Generates a proposal based on a brief received by you, plus some additional inputs.</li>
							<li>Gives you the opportunity to provide additional inputs such as target customers for interviews, sample size, centers for primery interviews etc.</li>
							<li>Allows you to specify your fee and payment terms.</li>
							<li>Allows you to provide a start and end date for the project.</li>
						</ul>
					</div>
				);
			case AI_JOB_DESCRIPTION:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Generates detailed Job Description with just a few lines of input.</li>
							<li>Automatically generates a brief about your company from the provided name.</li>
							<li>Can be used to generate a requirement for employment or secondment.</li>
							<li>Takes several inputs and incorporates then in the Job Description such as Annual CTC budget, experience required, job location, designation etc.</li>
						</ul>
					</div>
				);
			case AI_CONTENT_ANALYSIS:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Saves you a lot fo time and effort by completely automating Content Analysis. </li>
							<li>Frees up your time for creative report writing. </li>
							<li>The generated output can be used by other GigApps for assisting you with report writing.</li>
						</ul>
					</div>
				);
			case AI_DISCUSSION_GUIDE:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Generates a Discussion Guide based on inputs given by you such as research objective, target respondents, interview length etc.</li>
							<li>Provides the ability to give input on specific sub-section you need, and the topic you want to be covered.</li>
							<li>Allows you to choose between generating Discussion Guide for Depth Interviews OR Focus Group Discussions (FGDs).</li>
						</ul>
					</div>
				);
			case AI_ANALYZE_QUALITATIVE_DATA:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Very simple to use; just upload your Content Analysis in the prescribed format and start asking questions to generate ideas for report writing.</li>
							<li>Substantially cuts the time taken in writing a report. </li>
							<li>Sticks to the content in the Content Analysis document alone when answering questions.</li>
							<li>Gives you an interview-wise finding as well as a summary from all the interviews. </li>
						</ul>
					</div>
				);
			case AI_QUERY_REPORT:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Very simple to use; just upload your files and start asking questions to get to insight faster.</li>
							<li>Substantially cuts the time taken in reaching the desired insight from historic reports.</li>
							<li>Sticks to the content in the uploaded files alone when answering questions.</li>
						</ul>
					</div>
				);
			case AI_PERFECT_QUESTION:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Very simple to use, just three inputs required.</li>
							<li>Provide three alternative question options that meet the stated objective and is appropriate for the target audience to whom the question is being posed.</li>
						</ul>
					</div>
				);
			case AI_SUGGEST_ANSWER_OPTION:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Very simple to use, just a few inputs required.</li>
							<li>Easily create comprehensive set of answer optionss for quantitative questions, and save time.</li>
						</ul>
					</div>
				);
			case AI_PRE_TEXT_PROCESSING:
				return (
					<div className={css.descriptionDetail}>
						<p>Easily perform the following pre-processing steps on open-ended responses of quantitative questionnaires, aiding better analysis.</p>
						<ul>
							<li>Spelling correction</li>
							<li>Correction of letter case </li>
							<li>Removal of punctuations</li>
							<li>Removal of stop words</li>
							<li>Stemming i.e. reducing an inflected word to its word stem</li>
							<li>Lemmatization i.e. grouping different inflected forms of a word</li>
							<li>Removal of emojis/emoticons</li>
							<li>Conversion of emojis/emoticons to words</li>
							<li>Removal of URLs</li>
							<li>Removal of HTML tags</li>
						</ul>
					</div>
				);
			case AI_KEYWORD_EXTRACTION:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Extract the keywords from the answers to an open-ended question instantly by just uploading the file.</li>
							<li>Perform addditional quantitative analysis on your own with the extracted topics.</li>
						</ul>
					</div>
				);
			case AI_TOPIC_MODELING:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Extract the keywords from the answers to an open-ended question instantly by just uploading the file.</li>
							<li>Quantification of open-ended comments in this manner helps you scale the analysis of such fields effectively.</li>
							<li>Perform addditional quantitative analysis on your own with the extracted topics.</li>
						</ul>
					</div>
				);
			case AI_SENTIMENT_ANALYSIS:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Quantify the sentiment of an open-ended question instantly by just uploading the file.</li>
							<li>Obtain some basic charts pertaining to the sentiment of the open-ended responses given by your customers.</li>
							<li>Perform addditional analysis on your own with the quantified sentiment column obtained as an output.</li>
						</ul>
					</div>
				);
			case AI_COMBINED_TOPIC_SENTIMENT:
				return (
					<div className={css.descriptionDetail}>
						<ul>
							<li>Generate a PDF report with an analysis of the topics and sentiment for those topics in a set of open-ended responses.</li>
							<li>Go from open-ended responses to insight and action in a matter of minutes!</li>
							<li>Perform addditional analysis on your own with the quantified topic and sentiment column obtained as an output.</li>
						</ul>
					</div>
				);
			case AI_RESEARCH_INSTRUMENT_COVERAGE_INDICATOR:
				return(
					<div className={css.descriptionDetail}>
						<ul>
							<li>An easy and fast way to check if your research instrument(s) achieve the study's objectives. Just upload the documents and watch the magic!</li>
						</ul>
					</div>
				)
			case AI_ANALYZE_RAW_QUANTITATIVE_DATA:
				return(
					<div className={css.descriptionDetail}>
						<ul>
							<li>Provides analysis for data from a quantittative survey almost instantly.</li>
							<li>Ability to ask questions about the data in natural language.</li>
							<li>Ability to upload any CSV or XLS file in the prescribed format, making the application platform agnostic.</li>
						</ul>
					</div>
				)
			case AI_TEXTUAL_CONCEPT_CREATER:
				return(
					<div className={css.descriptionDetail}>
						<ul>
							<li>Generate multiple versions of your concept in minutes. Never face writer's block again.</li>
						</ul>
					</div>
				)
			case AI_RESEARCH_INSTRUMENT_TRANSLATOR:
				return(
					<div className={css.descriptionDetail}>
						<ul>
							<li>Faster and cheaper than manual translation approach. Just upload the research instrument and watch the magic!</li>
						</ul>
					</div>
				)
			case AI_OPEN_ENDED_RESPONSE_TRANSLATOR:
				return(
					<div className={css.descriptionDetail}>
						<ul>
							<li>Faster and cheaper than manual translation approach.</li>
						</ul>
					</div>
				)
			case AI_TRANSLATE_TRANSCRIPT:
				return(
					<div className={css.descriptionDetail}>
						<ul>
							<li>Faster and cheaper than manual translation approach. Just upload the transcript and watch the magic!</li>
						</ul>
					</div>
				)
			case AI_EXTRACT_QUESTIONS_FROM_TRANSCRIPTS:
				return(
					<div className={css.descriptionDetail}>
						<ul>
							<li>Never miss on any insight by using the first party data to generate the discussion guide.</li>
							<li>Easily generate a discussion guide from transcripts in case you lost the original document!</li>
						</ul>
					</div>
				)
			case AI_ANALYZE_UNSTRUCTURED_DATA:
				return(
					<div className={css.descriptionDetail}>
						<ul>
							<li>Very simple to use; just upload unstructured data in the prescribed format and start asking questions to generate ideas for report writing.</li>
							<li>Ability to export the chat transcript as a Word or PDF file.</li>
							<li>Ability to export the chat transcript as a Word or PDF file.</li>
							<li>Sticks to the content in the uploaded document alone when answering questions.</li>
						</ul>
					</div>
				)
			default:
				return null;
		}
	}
	return (
		<>
			{handleFeatures()}
		</>
	)
}

export default OpenAIFeaturesPanel