import React, { useEffect, useState } from 'react';
import css from './OpenAIAppsPanel.module.css';
import {
  OpenAIBriefGeneratorForm,
  OpenAIContractForm,
  OpenAIDiscussionGuideForm,
  OpenAIJobDescriptionForm,
  OpenAIPerfectQuestionAppForm,
  OpenAIProposalForm,
  OpenAIQueryReportForm,
  OpenAIQuestionnaireForm,
  OpenAIReportIdeaGeneratorForm,
  OpenAISuggestAnswerOptionAppForm,
  OpenAITextPreProcessingForm,
  OpenAITextualConceptCreatorForm,
  OpenAITranscriptionForm,
} from '../../forms';
import IconCard from '../IconCard/IconCard';
import QuillTextEditor from '../QuillTextEditor/QuillTextEditor';
import NamedLink from '../NamedLink/NamedLink';
import { handleFileUpload, sendEmailUsingZepto, storeOpenAIData, updateFirmUserDetails, updateOpenAIData } from '../../util/api';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import Modal from '../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  AI_BRIEF_GENERATOR,
  AI_CONTENT_ANALYSIS,
  AI_CONTRACT,
  AI_DISCUSSION_GUIDE,
  AI_JOB_DESCRIPTION,
  AI_PERFECT_QUESTION,
  AI_PROPOSAL,
  AI_QUERY_REPORT,
  AI_QUESTIONNAIRE,
  AI_ANALYZE_QUALITATIVE_DATA,
  AI_TRANSCRIPTION,
  AI_SUGGEST_ANSWER_OPTION,
  USER_ROLE_PARTNER,
  AI_PRE_TEXT_PROCESSING,
  AI_KEYWORD_EXTRACTION,
  AI_TOPIC_MODELING,
  AI_SENTIMENT_ANALYSIS,
  USER_ROLE_CSM,
  AI_COMBINED_TOPIC_SENTIMENT,
  MAX_GIG_APPS_LIMIT,
  AI_RESEARCH_INSTRUMENT_COVERAGE_INDICATOR,
  AI_ANALYZE_RAW_QUANTITATIVE_DATA,
  AI_TEXTUAL_CONCEPT_CREATER,
  AI_RESEARCH_INSTRUMENT_TRANSLATOR,
  GIG_APP_USAGE,
  AI_OPEN_ENDED_RESPONSE_TRANSLATOR,
  AI_TRANSLATE_TRANSCRIPT,
  AI_EXTRACT_QUESTIONS_FROM_TRANSCRIPTS,
  AI_ANALYZE_UNSTRUCTURED_DATA
} from '../../util/types';
import OpenAIAppDescriptionPanel from './OpenAIAppDescriptionPanel';
import OpenAIFeaturesPanel from './OpenAIFeaturesPanel';
import OpenAICategoryPanel from './OpenAICategoryPanel';
import {
  checkIfUserSubscribed,
  getUserDetails,
  offlineSubscriptionObject,
  userSubscriptions,
} from '../../util/destructorHelpers';
import { v4 as uuidv4 } from 'uuid';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PrimaryButton } from '../Button/Button';
import { appsData as apps, macroApps } from '../GigAppsPanel/appsData';
import ExternalLink from '../ExternalLink/ExternalLink';
import { getUserRole } from '../../util/userRole';
import OpenAIContentAnalysisForm from '../../forms/OpenAIContentAnalysisForm/OpenAIContentAnalysisForm';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import ActiveSession from './ActiveSession';
import {
  handleBriefGeneration,
  handleReportIdeaGeneration,
  handleContractGeneration,
  handleDiscussionGuideGeneration,
  handleJobDescriptionGeneration,
  handlePerfectQuestionAppData,
  handleProposalGeneration,
  handleQueryReport,
  handleQuestionnaireGeneration,
  handleSuggestedAnswerGenerator,
  handleDjangoRequest,
  handleTranscriptionGenerator,
  handleResearchInstrumentGenerator,
  handleAnalyzeRawData,
  handleTextualConceptGeneration,
  handleResearchInstrumentTranslator,
  handleSendAdminEmail,
  handleAnalyzeRawFinalMerge,
  handleExtractQuestionsFromTranscripts,
} from './gigAppsHelperFunction';
import OpenAIDIYAndAssitedModal from './OpenAIDIYAndAssitedModal';
import OpenAIReportSuccessModal from '../OpenAIReportSuccessModal/OpenAIReportSuccessModal';
import { compose } from 'redux';
import OpenAIResearchInstrumentForm from '../../forms/OpenAiResearchInstrumentForm/OpenAIResearchInstrumentForm';
import OpenAIAnalyzeRawQuantitativeDataForm from '../../forms/OpenAIAnalyzeRawQuatitativeDataForm/OpenAIAnalyzeRawQuantitativeDataForm';
import OpenAIResearchTranslatorForm from '../../forms/OpenAIResearchInstrumentTranslatorForm/OpenAIResearchTranslatorForm';
import OpenAIPermissionTab from './OpenAIPermissionTab';
import OpenAIExtractQuestionsFromTranscriptsForm from '../../forms/OpenAIExtractQuestionsFromTranscriptsForm/OpenAIExtractQuestionsFromTranscriptsForm';

const OpenAIAppsPanel = props => {
  const { history, pathname, appTitle, appRoute, assistedFlowInProgress, intl } = props;
  const dispatch = useDispatch();
  const [initialText, setInitialText] = useState({});
  const [openaiRequest, setOpenaiRequest] = useState(false);
  const [uniqueInputId, setUniqueInputId] = useState(uuidv4());
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [error, setError] = useState('');
  const [isOpenAIAppsAssistedModal, setIsOpenAIAppsAssistedModal] = useState(false);
  const [openMaxUsageModal, setOpenMaxUsageModal] = useState(false);
  const [showResearchModal, setShowResearchModal] = useState(false);
  const [formStep,setFormStep] = useState(1);
  const [questionnaireResponseModal, setQuestionnaireResponseModal] = useState(false);
  const [analyzeFileError, setAnalyzeFileError] = useState(false);
  const [proposalSucess, setProposalSuccess] = useState(false);

  const { currentUser = {} } = useSelector(state => state.user) || {};
  const currentUserEmail = getUserDetails(currentUser).email;
  const currentUserName = getUserDetails(currentUser).fullName;
  const currentUserTimezone = getUserDetails(currentUser).currentUserTimezone;
  const currentUserFirstName = getUserDetails(currentUser).firstName;
  const currentUserRole = getUserRole(currentUser);
  const isClient = currentUserRole !== USER_ROLE_PARTNER;
  const isCsm = currentUserRole === USER_ROLE_CSM;
  const nonOpenaiApps = [
    AI_PRE_TEXT_PROCESSING,
    AI_KEYWORD_EXTRACTION,
    AI_TOPIC_MODELING,
    AI_SENTIMENT_ANALYSIS,
    AI_COMBINED_TOPIC_SENTIMENT,
  ];

  const currentAppData = apps?.find(app => app.route === appRoute);
  const { tags, category, description, name, logo, pricing } = currentAppData || {};
  const onlineSubscriptionData = currentUser?.id && userSubscriptions(currentUser)?.find(sub => sub.plan)?.plan;
  const linkedToFirms = getUserDetails(currentUser)?.linkedToFirms || [];
  const firmDetails = linkedToFirms?.[0];
  const {isOwnerHasSubscription} = firmDetails || {};

  const isUserSubscribed = isClient 
    ? (checkIfUserSubscribed(currentUser) || !!linkedToFirms?.length)
    : (checkIfUserSubscribed(currentUser) || isOwnerHasSubscription);
  const gigAppUsageTotal = currentUser?.id && currentUser?.subscriptionData?.gigAppUsageCount;
  const isCurrentMonthLimitReached = isUserSubscribed && gigAppUsageTotal >= MAX_GIG_APPS_LIMIT;
  const hasMacroAppAccess = currentUser?.attributes?.profile?.publicData?.hasMacroAppAccess;

  async function storeUserInput(values, runName, expiresAt) {
    return await storeOpenAIData({
      runName: runName || appRoute,
      type: appRoute,
      email: currentUserEmail,
      id: uniqueInputId,
      data: values,
      expiresAt
    });
  }

  function handleError(message) {
    setError(message);
    setOpenaiRequest(false);
    setTimeout(() => setError(false), 3000);
  }

  //Update gig-app usage data in mongo database
  function updateAppUsageData(){
    //Store details in user's Firm
    updateFirmUserDetails({ action: GIG_APP_USAGE, userEmail: currentUserEmail, appName: appRoute })
  }

  const openAIAppsForm = () => {
    const defaultProps = {
      id: uniqueInputId,
      storeUserInput,
      setOpenaiRequest,
      setInitialText,
      handleError,
      appRoute,
      isUserSubscribed,
      email: currentUserEmail,
      title: intl.formatMessage({id: appTitle.props.id}),
      firstName: currentUserFirstName,
      timezone: currentUserTimezone,
    };

    switch (appRoute) {
      case AI_BRIEF_GENERATOR:
        return (
          <OpenAIBriefGeneratorForm
            onSubmit={values => {
              updateAppUsageData()
              handleBriefGeneration({ values, ...defaultProps })
            }}
            openaiRequest={openaiRequest}
            initialText={initialText}
            isError={<p className={css.error}>{error}</p>}
          />
        );
      case AI_QUESTIONNAIRE:
        return (
          <OpenAIQuestionnaireForm
            onSubmit={(values,form) => {
              updateAppUsageData()
              handleQuestionnaireGeneration({ form, values, setQuestionnaireResponseModal, ...defaultProps, firstName: currentUserFirstName })
            }}
            openaiRequest={openaiRequest}
            initialText={initialText}
            responseModal={questionnaireResponseModal}
            setResponseModal={setQuestionnaireResponseModal}
            initialValues={{step:1}}
            uniqueInputId={uniqueInputId}
            email={currentUserEmail}
            appRoute={appRoute}
            onManageDisableScrolling={(componentId, disableScrolling) =>
              dispatch(manageDisableScrolling(componentId, disableScrolling))
            }
            handleRedirectToGigAppsPage={() => {
              history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
            }}
          />
        );
      case AI_TRANSCRIPTION:
        return (
          <OpenAITranscriptionForm
            initialValues={{screenshotsRequired: false}}
            onSubmit={values => {
              updateAppUsageData()
              handleTranscriptionGenerator({
                ...defaultProps,
                values,
                setOpenSuccessModal,
              });
            }}
            openaiRequest={openaiRequest}
            initialText={initialText}
          />
        );
      case AI_CONTRACT:
        return (
          <OpenAIContractForm
            onSubmit={values => {
              updateAppUsageData()
              handleContractGeneration({ values, ...defaultProps })
            }}
            openaiRequest={openaiRequest}
            initialText={initialText}
            manageDisableScrolling={(componentId, disableScrolling) =>
              dispatch(manageDisableScrolling(componentId, disableScrolling))
            }
          />
        );
      case AI_PROPOSAL:
        return (
          <OpenAIProposalForm
            setProposalSuccess={setProposalSuccess}
            proposalSucess={proposalSucess}
            onSubmit={values => {
              updateAppUsageData()
              handleProposalGeneration({ values,uniqueInputId, ...defaultProps, setProposalSuccess })
            }}
            openaiRequest={openaiRequest}
            initialText={initialText}
            handleRedirectToGigAppsPage={() => {
              history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
            }}
            manageDisableScrolling={(componentId, disableScrolling) =>
              dispatch(manageDisableScrolling(componentId, disableScrolling))
            }
          />
        );
      case AI_JOB_DESCRIPTION:
        return (
          <OpenAIJobDescriptionForm
            onSubmit={values => {
              updateAppUsageData()
              handleJobDescriptionGeneration({ values, ...defaultProps })
            }}
            openaiRequest={openaiRequest}
            initialText={initialText}
          />
        );
      case AI_CONTENT_ANALYSIS:
        return (
          <OpenAIContentAnalysisForm
            onSubmit={() => { }}
            timeZone={currentUserTimezone}
            storeUserInput={storeUserInput}
            currentUserEmail={currentUserEmail}
            uniqueInputId={uniqueInputId}
            currentUserName={currentUserName}
            firstName={currentUserFirstName}
            appTitle={intl.formatMessage({id: appTitle.props.id})}
            handleRedirectToGigAppsPage={() => {
              history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
            }}
            manageDisableScrolling={(componentId, disableScrolling) =>
              dispatch(manageDisableScrolling(componentId, disableScrolling))
            }
            appRoute={appRoute}
            storeModifiedQuestion={async (id, questions) => {
              return await updateOpenAIData({type: appRoute, id: id, data: questions})
            }}
            updateAppUsageData={updateAppUsageData}
          />
        );
      case AI_DISCUSSION_GUIDE:
        return (
          <OpenAIDiscussionGuideForm
            onSubmit={values => {
              updateAppUsageData()
              handleDiscussionGuideGeneration({ values, ...defaultProps })
            }}
            openaiRequest={openaiRequest}
            initialText={initialText}
          />
        );
      case AI_QUERY_REPORT:
        return (
          <OpenAIQueryReportForm
            onSubmit={values => {
              updateAppUsageData()
              handleQueryReport({
                values,
                setOpenaiRequest,
                setOpenSuccessModal,
                handleError,
                currentUserEmail,
                uniqueInputId,
                appRoute,
                storeUserInput,
                firstName: currentUserFirstName,
                ...defaultProps
              })
            }}
            openaiRequest={openaiRequest}
            isError={error}
          />
        );
      case AI_ANALYZE_QUALITATIVE_DATA:
      case AI_ANALYZE_UNSTRUCTURED_DATA:
        return(
          <OpenAIReportIdeaGeneratorForm
            onSubmit={values => {
              updateAppUsageData()
              handleReportIdeaGeneration({
                values,
                setOpenaiRequest,
                setOpenSuccessModal,
                handleError,
                currentUserEmail,
                uniqueInputId,
                appRoute,
                firstName: currentUserFirstName,
                storeUserInput,
                ...defaultProps
              })
            }}
            openaiRequest={openaiRequest}
            isError={<p className={css.error}>{error}</p>}
            appRoute={appRoute}
          />
        )
      case AI_PERFECT_QUESTION:
        return (
          <OpenAIPerfectQuestionAppForm
            onSubmit={(values, form) => {
              updateAppUsageData()
              handlePerfectQuestionAppData({ ...defaultProps, values, form})
            }}
            openaiRequest={openaiRequest}
            isReportApp={true}
            isError={error}
          />
        );
      case AI_SUGGEST_ANSWER_OPTION:
        return (
          <OpenAISuggestAnswerOptionAppForm
            onSubmit={(values, form) => {
              updateAppUsageData()
              handleSuggestedAnswerGenerator({ ...defaultProps, values, form})
            }}
            openaiRequest={openaiRequest}
            isReportApp={true}
            isError={error}
          />
        );
      case AI_RESEARCH_INSTRUMENT_COVERAGE_INDICATOR:
        return(
          <OpenAIResearchInstrumentForm
            appTitle={appTitle}
            showModal={showResearchModal}
            setShowModal={setShowResearchModal}
            formStep={formStep}
            setFormStep={setFormStep}
            onSubmit={(values,form) => {
              updateAppUsageData()
              handleResearchInstrumentGenerator({
                values,
                form,
                storeUserInput,
                uniqueInputId,
                setShowResearchModal,
                formStep,
                setFormStep,
                ...defaultProps,
              })
            }}
            handleRedirectToGigAppsPage={() => {
              history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
            }}
            manageDisableScrolling={(componentId, disableScrolling) =>
              dispatch(manageDisableScrolling(componentId, disableScrolling))
            }
          />
      );
      case AI_ANALYZE_RAW_QUANTITATIVE_DATA:
        return(
          <OpenAIAnalyzeRawQuantitativeDataForm
            initialValues={{step: 1}}
            appTitle={appTitle}
            appRoute={appRoute}
            analyzeFileError={analyzeFileError}
            setAnalyzeFileError={setAnalyzeFileError}
            openaiRequest={openaiRequest}
            handleRedirectToGigAppsPage={() => {
              history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
            }}
            onSubmit={(values, form)=> {
              if(values.step === 3){
                updateAppUsageData()
                handleAnalyzeRawData({...defaultProps, values, form , setAnalyzeFileError})
              } else if(values.step === 4){
                handleAnalyzeRawFinalMerge({...defaultProps, values, form})
              }
            }}
            manageDisableScrolling={(componentId, disableScrolling) =>
              dispatch(manageDisableScrolling(componentId, disableScrolling))
            }
          />
        )
      case AI_PRE_TEXT_PROCESSING:
      case AI_KEYWORD_EXTRACTION:
      case AI_TOPIC_MODELING:
      case AI_SENTIMENT_ANALYSIS:
      case AI_COMBINED_TOPIC_SENTIMENT:
        return (
          <OpenAITextPreProcessingForm
            appTitle={appTitle}
            onSubmit={(values, form) => {
              updateAppUsageData()
              handleFileUpload('upload-gigapp-input-file-aws', {file : values.gigAppInputFiles, email: currentUserEmail})

              return handleDjangoRequest({...defaultProps, values, form})
            }}
            isError={error}
            appRoute={appRoute}
            manageDisableScrolling={(componentId, disableScrolling) =>
              dispatch(manageDisableScrolling(componentId, disableScrolling))
            }
            handleRedirectToGigAppsPage={() => {
              history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
            }}
            openaiRequest={openaiRequest}
          />
        );
      case AI_TEXTUAL_CONCEPT_CREATER:
        return (
          <OpenAITextualConceptCreatorForm
            onSubmit={(values, form) => {
              updateAppUsageData()
              handleTextualConceptGeneration({ values, ...defaultProps})
            }}
            openaiRequest={openaiRequest}
            initialText={initialText}
            isError={<p className={css.error}>{error}</p>}
          />
        );
      case AI_RESEARCH_INSTRUMENT_TRANSLATOR: 
      case AI_OPEN_ENDED_RESPONSE_TRANSLATOR: 
      case AI_TRANSLATE_TRANSCRIPT: {
        const isTranslateTranscriptApp = appRoute === AI_TRANSLATE_TRANSCRIPT;
        const isResponseTranslatorApp = appRoute === AI_OPEN_ENDED_RESPONSE_TRANSLATOR;
        return(
          <OpenAIResearchTranslatorForm
            onSubmit={(values, form) => {
              updateAppUsageData() 
              handleResearchInstrumentTranslator({ values, ...defaultProps, form, isResponseTranslatorApp, isTranslateTranscriptApp})
            }}
            initialValues={{success:false}}
            openaiRequest={openaiRequest}
            manageDisableScrolling={(componentId, disableScrolling) =>
              dispatch(manageDisableScrolling(componentId, disableScrolling))
            }
            handleRedirectToGigAppsPage={()=>{
              history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
            }}
            appRoute={appRoute}
            isResponseTranslatorApp={isResponseTranslatorApp}
            isTranslateTranscriptApp={isTranslateTranscriptApp}
          />
        )
      }
      case AI_EXTRACT_QUESTIONS_FROM_TRANSCRIPTS: {
        return(
          <OpenAIExtractQuestionsFromTranscriptsForm
            onSubmit={(values, form) => {
              handleExtractQuestionsFromTranscripts({values, ...defaultProps, form, setOpenaiRequest})
            }}
            openaiRequest={openaiRequest}
            setInitialText={setInitialText}
            manageDisableScrolling={(componentId, disableScrolling) =>
              dispatch(manageDisableScrolling(componentId, disableScrolling))
            }
            handleRedirectToGigAppsPage={()=>{
              history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
            }}
          />
        )
      }
      default:
        return null;
    }
  };

  // Handle click on app thumbail
  function handleAppRedirect(route) {
    history.push(
      createResourceLocatorString('OpenAiAppsPage', routeConfiguration(), { slug: route })
    );
  }

  function handleUseAppButton() {
    try {
      if (macroApps.includes(appRoute)) {
        setIsOpenAIAppsAssistedModal(true);
        return;
      } 
      if(isCurrentMonthLimitReached){
        setOpenMaxUsageModal(true)
        return
      }
      if (!isCsm && !isUserSubscribed) {
        setOpenAlertModal(true);
        return;
      }
      else {
        history.push(
          createResourceLocatorString('OpenAiAppsPageForm', routeConfiguration(), {
            slug: appRoute,
          })
        );
      }
    } catch (error) { }
  }

  function handleClickSession(id, type) {
    let pageName = 'OpenAiAppsPageEditForm';
    history.push(
      createResourceLocatorString(pageName, routeConfiguration(), {
        slug: appRoute,
        id,
        type: type ? type : 'edit'
      })
    );
  }

  useEffect(() => {
    if(currentUser?.id && !isUserSubscribed && pathname.includes('/form')){
      setOpenAlertModal(true);
    }
  }, [pathname, currentUser?.id])

  function handleModalSubmitButton(){
    if(pathname.includes('form')) history.goBack()
    else if(openAlertModal === 'hasMacroAppAccess'){
    
      //Send InsightGig admin email
      sendEmailUsingZepto({
        step: 'macroGigAppAssistance',
        userName: currentUserName,
        userEmail: currentUserEmail,
        appName: name,
      })
      setOpenAlertModal(false)
    }
    else {
      history.push('/account/subscription');
    }
  }

  return (
    <div className={css.openAIBriefGeneratorPanel}>
      {pathname.includes('/form') ? (
        openAIAppsForm()
      ) : (
        <div className={css.applicationBox}>
          <span className={css.browserBack} onClick={() => history.goBack()}>
            <IconCard brand="backArrow" />
          </span>
          <div className={css.headerContainer}>
            <img src={logo?.replace('/upload/', '/upload/c_fill,w_163,h_163/') || logo} />
            <div className={css.headerWrapper}>
              <div>
                <h1 className={css.headerName}>{name}</h1>
                <p className={css.descriptionName}>{description}</p>
              </div>
              <PrimaryButton onClick={handleUseAppButton} disabled={isCsm}>
                <FormattedMessage id="OpenAiAppsPanel.useAppButtonText" />
              </PrimaryButton>
            </div>
          </div>
          <PrimaryButton onClick={handleUseAppButton} className={css.headerButton}>
            <FormattedMessage id="OpenAiAppsPanel.useAppButtonText" />
          </PrimaryButton>

          {[AI_CONTENT_ANALYSIS, AI_RESEARCH_INSTRUMENT_COVERAGE_INDICATOR, AI_QUESTIONNAIRE, AI_ANALYZE_RAW_QUANTITATIVE_DATA, AI_EXTRACT_QUESTIONS_FROM_TRANSCRIPTS].includes(appRoute) && (
            <ActiveSession 
              appRoute={appRoute}
              email={currentUserEmail} 
              redirectToForm={handleClickSession} 
              onManageDisableScrolling={(componentId, disableScrolling) =>
                dispatch(manageDisableScrolling(componentId, disableScrolling))
              }
            />
          )}
          <OpenAICategoryPanel
            tags={tags}
            category={category}
            appRoute={appRoute}
            pricing={pricing}
          />
          <div className={css.appImageContainer}>
            <img src={logo} />
          </div>
          <OpenAIAppDescriptionPanel appRoute={appRoute} />
          <div className={css.summaryBlock}>
            <h3><FormattedMessage id="OpenAiAppsPanel.featureLabel" /></h3>
            <OpenAIFeaturesPanel appRoute={appRoute} />
          </div>
          <OpenAIPermissionTab 
            nonOpenaiApps={nonOpenaiApps}
            appRoute={appRoute}
          />
          <div className={css.recommendedAppsContainer}>
            <h3 className={css.groupHeading}>
              <FormattedMessage id="GigAppsPanel.newAppsTitle" />
            </h3>
            <div className={css.lovedAppsContainer}>
              {apps.slice(-2).map(app => (
                <div
                  className={css.newAppBox}
                  key={app.name}
                  onClick={() => handleAppRedirect(app.route)}
                >
                  <img src={app.logo} />
                  <div className={css.appDescription}>
                    <h4>{app.name}</h4>
                    <p>{app.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {!!initialText?.isOpen && (
        <QuillTextEditor
          initialText={initialText}
          appTitle={intl.formatMessage({id: appTitle.props.id})}
          appRoute={appRoute}
          email={currentUserEmail}
          firstName={currentUserFirstName}
          id={uniqueInputId}
          refreshUniqueId={()=> {
            setUniqueInputId(uuidv4())
          }}
          setInitialText={setInitialText}
          onManageDisableScrolling={(componentId, disableScrolling) =>
            dispatch(manageDisableScrolling(componentId, disableScrolling))
          }
          handleRedirectToGigAppsPage={() => {
            history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
          }}
          history={history}
        />
      )}

      <OpenAIReportSuccessModal
        history={history}
        openSuccessModal={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
        dispatch={dispatch}
        appRoute={appRoute}
      />

      <Modal
        id="openAlertModal"
        isOpen={openAlertModal}
        onClose={() => setOpenAlertModal(false)}
        usePortal
        removeCloseButton={true}
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.alertModal}>
          {openAlertModal === 'hasMacroAppAccess' ? (
            <>
              <h2><FormattedMessage id="OpenAiAppsPanel.macroAppAlertTitle" /></h2>
              <p><FormattedMessage id="OpenAiAppsPanel.macroAppAlertDescription" /></p>
            </>
          ) : (
            <>
              <h2><FormattedMessage id="OpenAiAppsPanel.alertModalTitle" /></h2>
              <p><FormattedMessage id="OpenAiAppsPanel.alertModalDescription" /></p>
            </>
          )}
          <div className={css.buttonWrapper}>
            <span className={css.cancelButton} onClick={() => {
              if(pathname.includes('form')) history.goBack()
              else setOpenAlertModal(false)
            }}>
              Cancel
            </span>
            <PrimaryButton onClick={handleModalSubmitButton}>
              <FormattedMessage id={openAlertModal === 'hasMacroAppAccess' ? 'OpenAiAppsPanel.emailButton' : "OpenAiAppsPanel.subscribeButton"} />
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <Modal
        id="usageLimitMaxedAlert"
        isOpen={openMaxUsageModal}
        onClose={() => setOpenMaxUsageModal(false)}
        usePortal
        removeCloseButton={true}
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <div className={css.alertModal}>
          <h2><FormattedMessage id="OpenAiAppsPanel.maxAppUsageTitle" /></h2>
          <p><FormattedMessage id="OpenAiAppsPanel.maxAppUsageMessage" /></p>
          <div className={css.buttonWrapper}>
            <span className={css.cancelButton} onClick={() => setOpenMaxUsageModal(false)}>
              Cancel
            </span>
            <PrimaryButton onClick={() => handleSendAdminEmail(currentUserFirstName, currentUserEmail, () => setOpenMaxUsageModal(false))}>
              Email InsightGig
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <OpenAIDIYAndAssitedModal
        setIsOpenAIAppsAssistedModal={setIsOpenAIAppsAssistedModal}
        isOpenAIAppsAssistedModal={isOpenAIAppsAssistedModal}
        currentAppData={currentAppData}
        isClient={isClient}
        isCsm={isCsm}
        history={history}
        isUserSubscribed={isUserSubscribed}
        assistedFlowInProgress={assistedFlowInProgress}
        currentUser={currentUser}
        setOpenAlertModal={setOpenAlertModal}
        appRoute={appRoute}
        hasMacroAppAccess={hasMacroAppAccess}
      />
    </div>
  );
};

export default compose(injectIntl)(OpenAIAppsPanel);
