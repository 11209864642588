import { Field, Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, SecondaryButton, IconCard, CaptureRunNameField, IconSpinner } from '../../components';
import css from './OpenAIReportIdeaGeneratorForm.module.css'
import { FormattedMessage } from 'react-intl';
import FileView from '../../components/FileView/FileView';
import { useEffect, useState } from 'react';
import { handleFileUpload } from '../../util/api';
import classNames from 'classnames';
import useHandleFileExtensionError from '../../hooks/useHandleFileExtensionError';
import { AI_ANALYZE_QUALITATIVE_DATA } from '../../util/types';

const ACCEPT_FILE = 'application/pdf, .doc, .docx, .ppt, .pptx';
const SINGLE_FILE_SIZE_LIMIT = 25000000;

const OpenAIReportIdeaGeneratorForm = (props) => {
  const {appRoute} = props;
  const isOldVersion = appRoute === AI_ANALYZE_QUALITATIVE_DATA;

  const extractProposalText = async (file, form, setIsLoading) => {
    setIsLoading(true)
    const response = await handleFileUpload('get-parsed-document', {file});
    const text = response?.data;
    form.change('proposalDocument', file);
    form.change('proposalDocumentText', text);
    setIsLoading(false)
  }

  const InputFileComponent = ({type, file, form, handleError, step, setError}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fileExtensionError, checkForFileExtension] = useHandleFileExtensionError();

    const handleRemoveFile = (type) => {
      form.change(type, {})
      if(type === 'proposalDocument') form.change('proposalDocumentText', null);
    }

    const handleCheckDataSetRows = async (file) => {
      setError(false)
      const response = await handleFileUpload('check-dataset-row-count', {file});

      if(isOldVersion && response?.data?.rows > 50) {
        handleError('We can currently handle a maximum of 50 rows on this app.')
        return  
      }
      else if(response?.data?.rows > 1000) {
        handleError('We can currently handle a maximum of 1,000 rows on this app.')
        return
      }
      form.change('transcription', file);
    };

    return(
      <div>
        {file?.name ? (
          <span key={file.name}>
            <FileView file={file}>
              <span className={css.close} onClick={() => handleRemoveFile(type)}>
                <IconCard brand='cross' />
              </span>
            </FileView>
          </span>
        ) : (
          <Field
            id="file"
            name="file"
            accept={step === 2 ? ACCEPT_FILE : '.xls, .xlsx'}
            type="file"
          >
            {fieldprops => {
              const { accept, input, disabled: fieldDisabled } = fieldprops;
              const { name, type } = input;
              const onChange = async e => {
                const file = e.target.files[0]
                let fileExtensionErrorMessage = `Please select file in ${step === 2 ? 'PDF, DOC, or PPT' : 'XLS or XLSX'} format only.`
                const allowedExtensions = step === 2 ? /(\.pdf|\.docx?|\.pptx?)$/i : /\.xlsx?$/i
                let extensionError = checkForFileExtension(allowedExtensions, file, fileExtensionErrorMessage)
                if(extensionError) return
                if (file.size > SINGLE_FILE_SIZE_LIMIT) {
                  handleError('File size can not be greater than 25mb!')
                  return
                }
                if(step === 2) extractProposalText(file, form, setIsLoading)
                else handleCheckDataSetRows(file)
              };
  
              const inputProps = { accept, id: name, name, onChange, type };
              return (
                <>
                  {fieldDisabled ? null : (
                    <input {...inputProps} className={css.addImageInput} />
                  )}
                  <label type='button' htmlFor={name} className={css.attachmentLabel}>
                    {isLoading ? <IconSpinner strokeColor='#0B96F5' /> : <IconCard brand="upload" />}
                    <span className={css.dragDrop}><FormattedMessage id="GigAppsPage.singleFileUpload" /></span>
                    <span className={css.docType}>
                      {step === 2 ? <FormattedMessage id="JobListing.documentAllowList" /> : 'XLS or XLSX' }
                    </span>
                  </label>
                  {fileExtensionError && <span className={css.error}>{fileExtensionError}</span>}
                </>
              );
            }}
          </Field>
        )}
      </div>
    )
  }

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          values,
          form,
          openaiRequest,
          isError,
        } = formRenderProps;

        const {step = 1} = values || {};
        const setStep = (value) => form.change('step', value);

        const [error, setError] = useState('');
        const [toggle, setToggle] = useState(true);
        const submitDisabled = step === 2 
          ? toggle 
            ? !values?.proposalDocumentText : false 
          : !values?.transcription?.name;

        function handleError(message) {
          setError(message)
          setTimeout(() => setError(false), 3000)
        }

        const handleNextStep = () => {
          if(step === 2){
            // form.change('proposalDocument', {});
            setStep(3)
          }
          else if(step === 3) handleSubmit()
        }

        function handleToggle() {
          if (toggle){
            setToggle(false)
            setError(false)
            form.change('proposalDocumentText', null);
            form.change('proposalDocument', {});
          }
          else setToggle(true)
        }

        return (
          <Form className={css.queryReportForm} onSubmit={(e) => e.preventDefault()}>
            <div className={css.container}>
              <div className={css.briefGenerator}>
                <span className={css.generatorTitle}>
                  <FormattedMessage id={isOldVersion ? "OpenAIReportGeneratorForm.heading" : "OpenAIReportGeneratorForm.newVersionHeading"} />
                </span>
              </div>
              <label className={css.firstFieldTitle}>
                {step > 1 && (
                  <span className={css.browserBack} onClick={() => form.change('step', step - 1)}>
                    <IconCard brand="backArrow" />
                  </span>
                )}
                Step {step}
              </label>
              <CaptureRunNameField step={step} form={form} values={values}>
                <div className={css.attachment}>
                  <div className={css.documentSwitcher}>
                    <label>
                      <FormattedMessage id={
                        step === 2 ? "OpenAIReportGeneratorForm.uploadProposalLabel" : 
                        isOldVersion ? "OpenAIReportGeneratorForm.uploadContentLabel" : "OpenAIReportGeneratorForm.uploadExcelFileLabel"
                      } />
                    </label>
                    {step === 2 && (
                      <div className={classNames(css.switcherWrapper, toggle && css.toggled)} onClick={handleToggle}>
                        <div className={css.ball}></div>
                      </div>
                    )}
                  </div>
                  {step !== 3 && !toggle ? null : (
                    <InputFileComponent 
                      type={step === 2 ? 'proposalDocument' : 'transcription'}
                      file={step === 2 ? values?.proposalDocument : values?.transcription} 
                      form={form} 
                      handleError={handleError} 
                      step={step}
                      setError={setError}
                    />
                  )}
                </div>
                {!!error && (
                  <p className={css.error}>{error}</p>
                )}
                {isError}
                <div className={css.actionButtons}>
                  <SecondaryButton
                    type='button'
                    className={css.resetButton}
                    onClick={() => form.reset()}
                    disabled={openaiRequest}
                  >
                    <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                  </SecondaryButton>
                  <PrimaryButton
                    type='button'
                    className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton )}
                    onClick={()=>{
                      if(submitDisabled){
                        handleError("Please choose a file first")
                      }else{
                        handleNextStep()
                      }
                    }}
                    inProgress={openaiRequest}
                  >
                    {step === 2 
                      ? 'Next' 
                      : <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                    }
                  </PrimaryButton>
                </div>
              </CaptureRunNameField>
            </div>
          </Form>
        )
      }}
    />
  )
}

export default OpenAIReportIdeaGeneratorForm