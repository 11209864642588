import { pick, pickBy } from 'lodash';
import {
  getAndDeleteNotifications,
  getAppTransaction,
  getTransaction,
  sendEmailUsingZepto,
  transitionPrivileged,
} from '../../util/api';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isNonEmpty, showFirmListing } from '../TransactionPage/TransactionPage.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
const { UUID } = sdkTypes;
import io from 'socket.io-client';
import { getUserDetails } from '../../util/destructorHelpers';
import { getTransition } from '../../util/transaction';
const URL = process.env.REACT_APP_CANONICAL_ROOT_URL;
// const socket = io(URL);
const socket = null;

// ================ Action types ================ //
export const ACTION_REQUEST = 'app/GigAppsTransactionPage/ACTION_REQUEST';
export const ACTION_SUCCESS = 'app/GigAppsTransactionPage/ACTION_SUCCESS';
export const ACTION_ERROR = 'app/GigAppsTransactionPage/ACTION_ERROR';

export const SET_INITIAL_VALUES = 'app/GigAppsTransactionPage/SET_INITIAL_VALUES';
export const INITIATE_ASSISTED_FLOW_REQUEST =
  'app/GigAppsTransactionPage/INITIATE_ASSISTED_FLOW_REQUEST';
export const INITIATE_ASSISTED_FLOW_SUCCESS =
  'app/GigAppsTransactionPage/INITIATE_ASSISTED_FLOW_SUCCESS';
export const INITIATE_ASSISTED_FLOW_ERROR =
  'app/GigAppsTransactionPage/INITIATE_ASSISTED_FLOW_ERROR';
export const FETCH_TRANSACTION_REQUEST = 'app/GigAppsTransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/GigAppsTransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/GigAppsTransactionPage/FETCH_TRANSACTION_ERROR';
export const CHANGE_PASSWORD_CLEAR = 'app/GigAppsTransactionPage/CHANGE_PASSWORD_CLEAR';

export const FETCH_TRANSACTION_MESSAGES = 'app/GigAppsTransactionPage/FETCH_TRANSACTION_MESSAGES';

export const NOTIFICATION_REQUEST = 'app/GigAppsTransactionPage/NOTIFICATION_REQUEST';
export const NOTIFICATION_SUCCESS = 'app/GigAppsTransactionPage/NOTIFICATION_SUCCESS';
export const NOTIFICATION_ERROR = 'app/GigAppsTransactionPage/NOTIFICATION_ERROR';

export const GIG_APP_INPUT_DATA_REQUEST = 'app/GigAppsTransactionPage/GIG_APP_INPUT_DATA_REQUEST';
export const GIG_APP_INPUT_DATA_SUCCESS = 'app/GigAppsTransactionPage/GIG_APP_INPUT_DATA_SUCCESS';
export const GIG_APP_INPUT_DATA_ERROR = 'app/GigAppsTransactionPage/GIG_APP_INPUT_DATA_ERROR';
// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  notifications: [],
  messages: [],
  notifications: [],
  notificationInProgress: false,
  notificationError: null,
  actionInProgress: false,
  actionError: null,
  gigAppInputDataInProgress: false,
  gigAppInputData: {},
  gigAppInputDataError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GIG_APP_INPUT_DATA_REQUEST:
      return { ...state, gigAppInputDataInProgress: true, actionError: null };
    case GIG_APP_INPUT_DATA_SUCCESS:
      return { ...state, gigAppInputData: payload, gigAppInputDataInProgress: false };
    case GIG_APP_INPUT_DATA_ERROR:
      return { ...state, gigAppInputDataInProgress: false, gigAppInputDataError: payload };
    case ACTION_REQUEST:
      return { ...state, actionInProgress: true, actionError: null };
    case ACTION_SUCCESS:
      return { ...state, actionInProgress: false };
    case ACTION_ERROR:
      return { ...state, actionInProgress: false, actionError: payload };
    case FETCH_TRANSACTION_MESSAGES:
      return { ...state, messages: payload };
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };
    case INITIATE_ASSISTED_FLOW_REQUEST:
      return {
        ...state,
        assistedFlowInProgress: true,
        assistedFlowError: null,
        passwordChanged: false,
      };

    case INITIATE_ASSISTED_FLOW_SUCCESS:
      return { ...state, assistedFlowInProgress: false };
    case INITIATE_ASSISTED_FLOW_ERROR:
      return { ...state, assistedFlowInProgress: false, assistedFlowError: payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };
    case NOTIFICATION_REQUEST:
      return { ...state, notificationInProgress: true, notificationError: null };
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationInProgress: false,
        notificationError: null,
        notifications: payload,
      };
    case NOTIFICATION_ERROR:
      return { ...state, notificationInProgress: false, notificationError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const assistedFlowRequest = () => ({ type: INITIATE_ASSISTED_FLOW_REQUEST });
export const assistedFlowSuccess = () => ({ type: INITIATE_ASSISTED_FLOW_SUCCESS });
export const assistedFlowError = error => ({
  type: INITIATE_ASSISTED_FLOW_ERROR,
  payload: error,
  error: true,
});
const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionMessages = response => ({
  type: FETCH_TRANSACTION_MESSAGES,
  payload: response,
});

const actionInProgress = () => ({ type: ACTION_REQUEST });
const actionSuccess = () => ({
  type: ACTION_SUCCESS,
});
const actionError = error => ({
  type: ACTION_ERROR,
  error: true,
  payload: error,
});

const gigAppInputDataRequest = () => ({ type: GIG_APP_INPUT_DATA_REQUEST });
const gigAppInputDataSuccess = response => ({
  type: GIG_APP_INPUT_DATA_SUCCESS,
  payload: response,
});
const gigAppInputDataError = error => ({
  type: GIG_APP_INPUT_DATA_ERROR,
  error: true,
  payload: error,
});

export const notificationRequest = () => ({
  type: NOTIFICATION_REQUEST,
});
export const notificationSuccess = data => ({
  type: NOTIFICATION_SUCCESS,
  payload: data,
});
export const notificationError = e => ({
  type: NOTIFICATION_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchTransaction = txId => async (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  try {
    const args = {
      id: txId,
      include: [
        'customer',
        'customer.profileImage',
        'provider',
        'provider.profileImage',
        'listing',
        'reviews',
        'reviews.author',
        'reviews.subject',
      ],
    };
    const response = txId && (await getTransaction({ txId: txId?.uuid }));
    // const response = await sdk.transactions.show(args);
    const { data } = response?.data || {};

    if (data && data.id) {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(response));
      return data.id.uuid;
    } else {
      // Handle the case where the response doesn't contain the expected data
      throw new Error('Invalid response structure');
    }
  } catch (error) {
    // Log the detailed error for debugging purposes

    dispatch(fetchTransactionError(storableError(error)));
    // You might want to rethrow the error here if you want it to be handled by the calling code
  }
};

export const loadMessages = params => async (dispatch, getState) => {
  try {
    if(!socket) return;

    socket?.removeAllListeners('messageResponse');
    socket.emit('loadMessages', {
      txId: params.id,
      chainedTransactionId: params.id,
    });

    const handleMessagesLoaded = async ({ messages }) => {
      dispatch(fetchTransactionMessages(messages));
    };

    const handleNoChatFound = async () => {
      dispatch(fetchTransactionMessages([]));
    };

    // const handleMessageResponse = async (data) => {
    //   const messages = getState().GigAppsTransactionPage.messages;
    //   dispatch(fetchTransactionMessages([...messages, data]));

    // };

    socket.once('messagesLoaded', handleMessagesLoaded); // Use 'once' to avoid multiple handlers
    socket.once('noChatFound', handleNoChatFound);
    // socket.on('messageResponse', handleMessageResponse);
  } catch (error) {
    // Handle errors appropriately, e.g., log errors or display user notifications
  }
};

export const fetchMessageResponse = data => async (dispatch, getState, sdk) => {
  try {
    const messages = getState().GigAppsTransactionPage.messages;
    dispatch(fetchTransactionMessages([...messages, data]));
  } catch (error) { }
};

export const getAppTransactionFromMongoDB = txId => async (dispatch, getState, sdk) => {
  dispatch(gigAppInputDataRequest());
  try {
    const response = await getAppTransaction({ txId });
    if (response) {
      dispatch(gigAppInputDataSuccess(response));
    }
  } catch (error) {
    dispatch(gigAppInputDataError(storableError(error)));

    return null;
  }
};

export const getAndRemoveNotifications = (currentUser, txId) => async (dispatch, getState, sdk) => {
  dispatch(notificationRequest());
  try {
    const { notifications = [] } = await getAndDeleteNotifications(currentUser.id.uuid);
    const filteredNotification =
      notifications[0]?.notificationSections?.filter(
        ({ transactionId }) => transactionId === txId
      ) ?? [];
    dispatch(notificationSuccess(filteredNotification));
  } catch (error) {
    dispatch(notificationError(storableError(error)));
  }
};

export const closeProject = params => async (dispatch, getState, sdk) => {
  dispatch(actionInProgress());
  try {
    const { id, transition } = params;
    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };

    const bodyParams = {
      id,
      transition,
      params: {},
    };
    const response = await transitionPrivileged({
      isTransition: true,
      bodyParams,
      queryParams,
    });
    dispatch(actionSuccess());
    dispatch(fetchTransaction(id));
    return response;
  } catch (error) {
    dispatch(actionError(storableError(error)));
  }
};

// Milestone

export const upsertMilestone = bodyParams => async (dispatch, getState, sdk) => {
  const { transaction, emailParams, currentUser, id, metadata, notification } = bodyParams;
  const { lastTransition } = transaction?.attributes || '';
  const txId = transaction?.id?.uuid;
  const { type = '' } = notification; //"addMilestone"

  try {
    const { customer = {}, provider = {} } = transaction?.id && transaction;
    const customerId = customer?.id && getUserDetails(customer).id;
    const providerName = provider?.id && getUserDetails(provider).fullName;
    const userEmail = currentUser?.id && getUserDetails(currentUser).email;
    const { collaborationMetaData = [] } =
      (transaction.id && transaction.attributes.metadata) || {};

    if (getState().TransactionPage.actionInProgress) {
      return Promise.reject(new Error('Action in progress'));
    }
    dispatch(actionInProgress());
    const isCollaborator = emailParams?.isCollaborator;
    const transition = getTransition(type, lastTransition, isCollaborator);
    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };

    const bodyParams = {
      id,
      transition,
      params: {
        metadata: {
          ...metadata,
          // notification,
        },
      },
    };
    const response = await transitionPrivileged({
      isTransition: true,
      bodyParams,
      queryParams,
    });
    // dispatch(addMarketplaceEntities(response));
    dispatch(fetchTransaction(transaction?.id));
    dispatch(actionSuccess());
    sendEmailUsingZepto({ ...emailParams, step: transition });
    return response;
  } catch (e) {
    dispatch(actionError(storableError(e)));
  }
};
export const upsertMilestoneThroughIsdk = bodyParams => async (dispatch, getState, sdk) => {
  const {
    currentTransaction,
    lastTransition,
    emailParams,
    currentUser,
    ...filteredParams
  } = bodyParams;

  const txId = currentTransaction.id.uuid;
  const { type = '' } = filteredParams && filteredParams.notification; //"addMilestone"

  try {
    const { customer = {}, provider = {} } = currentTransaction?.id && currentTransaction;
    const customerId = customer?.id && getUserDetails(customer).id;
    const providerName = provider?.id && getUserDetails(provider).fullName;
    const userEmail = currentUser?.id && getUserDetails(currentUser).email;
    const { collaborationMetaData = [] } =
      (currentTransaction.id && currentTransaction.attributes.metadata) || {};

    if (getState().TransactionPage.actionInProgress) {
      return Promise.reject(new Error('Action in progress'));
    }
    dispatch(actionInProgress());
    const isCollaborator = emailParams?.isCollaborator;
    const transition = getTransition(type, lastTransition, isCollaborator);
    // const isMilestoneCreated = await sdk.transactions.transition(
    //   { id: filteredParams.id, transition, params: {} },
    //   { expand: true }
    // );
    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };

    const bodyParams = {
      id: filteredParams.id,
      transition,
      params: {
        metadata: {},
      },
    };
    const response = await transitionPrivileged({
      isTransition: true,
      bodyParams,
      queryParams,
    });

    dispatch(addMarketplaceEntities(response));

    dispatch(actionSuccess());
    sendEmailUsingZepto({ ...emailParams, step: transition });
    // }
  } catch (e) {
    dispatch(actionError(storableError(e)));
  }
};
// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => async (dispatch, getState) => {
  try {
    const txId = new UUID(params.id);
    const state = getState().GigAppsTransactionPage;
    const txRef = state.transactionRef;
    // const txRole = params.transactionRole;

    // In case a transaction reference is found from a previous
    // data load -> clear the state. Otherwise keep the non-null
    // and non-empty values which may have been set from a previous page.
    const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
    dispatch(setInitialValues(initialValues));
    // const { currentUser = {} } = getState().user || {};
    const currentUser = await dispatch(fetchCurrentUser());
    // Sale / order (i.e. transaction entity in API)
    return Promise.all([
      dispatch(fetchTransaction(txId)),
      dispatch(loadMessages(params)),
      dispatch(getAppTransactionFromMongoDB(params.id)),
      dispatch(getAndRemoveNotifications(currentUser, params.id)),
      dispatch(showFirmListing()),
    ]);
  } catch (error) {
    dispatch(actionError(storableError(error)));
  }
};
