import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { GigAppsSearchForm } from '../../forms';
import css from './GigAppsPanel.module.css';
import GigAppsFilter, { filters } from './GigAppsFilter';
import { FormattedMessage } from 'react-intl';
import { AI_BRIEF_GENERATOR, AI_CONTENT_ANALYSIS, AI_PROPOSAL, AI_QUERY_REPORT, AI_ANALYZE_QUALITATIVE_DATA, GIG_APP_VIEWS } from '../../util/types';
import logo from '../../assets/insightgig_favicon_22:1:25.png';
import { v4 as uuidv4 } from 'uuid';
import IconCard from '../IconCard/IconCard';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { useDispatch } from 'react-redux';
import { getCsmBannerTime, getUserDetails } from '../../util/destructorHelpers';
import CsmSupportBanner from '../CsmSupportBanner/CsmSupportBanner';
import { appsData as apps} from './appsData';
import NamedLink from '../NamedLink/NamedLink';
import { fetchGigAppUsageData, updateFirmUserDetails } from '../../util/api';

const GigAppsPanel = (props) => {
  const { history, location, currentUser, isClient } = props;
  const categoryFrom = !!location.state && location.state.category;
  const dispatch = useDispatch();
  const OPEN_AI_PAGE_NAME = 'OpenAiAppsPage';

  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [mostUsedApps, setMostUsedApps] = useState([AI_BRIEF_GENERATOR, AI_CONTENT_ANALYSIS]);
  
  const currentUserEmail = getUserDetails(currentUser).email;

  useEffect(() => {
    try{
      // Find gig-app with the highest usage values
      async function fetchData(){
        const response = await fetchGigAppUsageData({email: currentUserEmail})
        if(response.length){
          const sortedData = response.sort((a, b) => b.count - a.count).map(item => item.type)
          const result = sortedData.slice(0, 2)
          setMostUsedApps(result)
        }
      }
      fetchData()
    }
    catch(error){
      console.log(error)
    }
  }, [currentUserEmail])

  const selectedFilterKeys = selectedFilter?.length ? selectedFilter?.map(filter => filter.label).join(',') : [];
  // Filter the apps based on the selected stageOfResearch
  const filteredApps = selectedFilter?.length
    ? apps.filter((app) => selectedFilterKeys.includes(app.category[0]))
    : searchTerm
      ? apps.filter(
        (app) =>
          app.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          app.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          app.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
      )
      : apps;
  
  const csmBannerTime = (currentUser?.id && getCsmBannerTime(currentUser)) || {};

  // Handle reset filter
  const resetFilter = () => {
    setSelectedFilter([]);
    setSearchTerm('');
    setIsFilterOpen(false)
  };

  // Handle form value changes here
  const handleFormValueChange = (value) => {
    setSearchTerm(value);
    // setStageOfResearch('');
  };

  // Handle remove selected filter
  function handleRemoveFilter(filterName) {
    const modifiedSelectedFilters = [...selectedFilter]?.filter(filter => filter.key !== filterName)
    setSelectedFilter(modifiedSelectedFilters)
  }

  //Update user micro gig-app usage record
  function updateFirmDashboardData(title){
    updateFirmUserDetails({ action: GIG_APP_VIEWS, userEmail: currentUserEmail, appName: title })
  }

  const LovedAppBox = ({ app }) => {
    const appLogo = app.logo.replace('/upload/', '/upload/c_fill,w_98,h_98/');
    return (
      <NamedLink className={css.lovedAppBox} name={OPEN_AI_PAGE_NAME} params={{slug: app.route}}>
        <div className={css.appDescription}>
          <span><FormattedMessage id='GigAppsPanel.lovedAppsSubtitle' /></span>
          <h4>{app.name}</h4>
          <p>{app.description}</p>
        </div>
        <img src={appLogo} />
      </NamedLink>
    )
  }

  const GigAppBox = ({ app }) => {
    const appLogo = app.logo.replace('/upload/', '/upload/c_fill,w_85,h_64/');
    return (
      <NamedLink className={css.gigAppBox} name={OPEN_AI_PAGE_NAME} params={{slug: app.route}}>
        <img src={appLogo} />
        <div className={css.appDescription}>
          <h4>{app.name}</h4>
          <p>{app.description}</p>
        </div>
      </NamedLink>
    )
  }

  const NewAppBox = ({ app }) => {
    const appLogo = app.logo.replace('/upload/', '/upload/w_480,h_480/');
    return (
      <NamedLink className={css.newAppBox} name={OPEN_AI_PAGE_NAME} params={{slug: app.route}}>
        <img src={appLogo} />
        <div className={css.appDescription}>
          <h4>{app.name}</h4>
          <p>{app.description}</p>
        </div>
      </NamedLink>
    )
  }

  const randomAppIndex = useMemo(() => {
    return [Math.floor(Math.random()*10), Math.floor(Math.random()*10)]
  }, [])
  const modifiedFilters = filters.map(filter => filter.items).flat();

  return (
    <div className={css.gigAppsPanel}>
      <div className={css.csmSupportBanner}>
        <CsmSupportBanner 
          isGigAppsPage={true}
          csmBannerTime={csmBannerTime} 
        />
      </div>
      <div className={css.gigAppsPanelSearch}>
        <div className={css.leftBox}>
          <div className={css.headerContainer}>
            <div className={css.headerLogo}>
              <img src={logo} alt='gigAppLogo' />
            </div>
            <h1><FormattedMessage id="GigAppsPanel.panelHeading" /></h1>
          </div>
          <div className={css.searchBarWrapper}>
            <GigAppsSearchForm
              onSubmit={() => { }}
              onValueChange={handleFormValueChange}
              resetFilter={resetFilter}
            />
            <span
              className={classNames(css.filterButton, !!selectedFilter?.length && css.selected)}
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <IconCard brand="filter" />
            </span>
          </div>
          <GigAppsFilter
            apps={apps}
            resetFilter={resetFilter}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
          />
        </div>
        <div className={css.rightBox}>
          {!(selectedFilter?.length || searchTerm) ? (
            <>
              {/* Loved Apps */}
              <div className={css.lovedAppsContainer}>
                {apps.map((app, index) => {
                  if(index === randomAppIndex[0] || index === randomAppIndex[1]) return(
                    <div key={uuidv4()} onClick={() => updateFirmDashboardData(app.route)}>
                      <LovedAppBox app={app} />
                    </div>
                  ) 
                  else return null
                })}
              </div>
              {/* Recommended Apps */}
              <div className={css.recommendedAppsContainer}>
                <h3 className={css.groupHeading}><FormattedMessage id="GigAppsPanel.recommendedAppsTitle" /></h3>
                <div className={css.recommendedApps}>
                  {apps.map((app) => {
                    const recommendedApps = [AI_BRIEF_GENERATOR, AI_CONTENT_ANALYSIS, AI_QUERY_REPORT, AI_ANALYZE_QUALITATIVE_DATA]
                    if(!recommendedApps.includes(app.route)) return null
                    return(
                      <div key={uuidv4()} onClick={() => updateFirmDashboardData(app.route)}>
                        <GigAppBox app={app} />
                      </div>
                    )
                  })}
                </div>
              </div>
              {/* New Apps */}
              <div className={css.recommendedAppsContainer}>
                <h3 className={css.groupHeading}><FormattedMessage id="GigAppsPanel.newAppsTitle" /></h3>
                <div className={css.lovedAppsContainer}>
                  {apps.slice(-2).map(app => (
                    <div key={uuidv4()} onClick={() => updateFirmDashboardData(app.route)}>
                      <NewAppBox app={app} />
                    </div>
                  ))}

                </div>
              </div>
              {/* Most Popular apps */}
              <div className={css.recommendedAppsContainer}>
                <h3 className={css.groupHeading}><FormattedMessage id="GigAppsPanel.mostPopularAppsTitle" /></h3>
                <div className={css.recommendedApps}>
                  {apps.map((app) => {
                    if(mostUsedApps.includes(app.route)) return(
                      <div key={uuidv4()} onClick={() => updateFirmDashboardData(app.route)}>
                        <GigAppBox app={app} />
                      </div>
                    ) 
                    else return null
                  })}
                </div>
              </div>
              {/* Pre-fieldwork */}
              <div className={css.recommendedAppsContainer}>
                {modifiedFilters.map(({label, key}) => {
                  const isFilterHasApp = !!apps.find(app => app.category.includes(label))
                  if(!isFilterHasApp) return null
                  return(
                    <Fragment key={key}>
                      <h3 className={css.groupHeading}>{label}</h3>
                      <div className={css.recommendedApps}>
                        {apps.filter(app => app.category.includes(label)).map((app) => (
                          <div key={uuidv4()} onClick={() => updateFirmDashboardData(app.route)}>
                            <GigAppBox app={app} />
                          </div>
                        ))}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            </>
          ) : (
            <>
              {selectedFilter?.length !== 0 && (
                <div className={css.selectedFiltersWrapper}>
                  <div>
                    <h2><FormattedMessage id="GigAppsPanel.filterAppliedText" /></h2>
                    <button onClick={() => setSelectedFilter([])}>
                      <FormattedMessage id="GigAppsPanel.clearFilterText" />
                    </button>
                  </div>
                  <div className={css.selectedFitlers}>
                    {selectedFilter?.map(filter => (
                      <label key={filter.label}>
                        {filter.label}
                        <span onClick={() => handleRemoveFilter(filter.key)}>
                          <IconCard brand='crossSign' />
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              )}
              {searchTerm && (
                <div className={css.searchTermText}>
                  <h3>"{searchTerm}"</h3>
                  <span>({filteredApps?.length})</span>
                </div>
              )}
              <div className={css.filteredApps}>
                {filteredApps?.map((app, index) => {
                  return(
                    <div key={index} onClick={() => updateFirmDashboardData(app.route)}>
                      <GigAppBox app={app}/>
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        id="GigAppsPanel.filter"
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onManageDisableScrolling={(componentId, disableScrolling) =>
          dispatch(manageDisableScrolling(componentId, disableScrolling))
        }
      >
        <GigAppsFilter
          apps={apps}
          setIsFilterOpen={setIsFilterOpen}
          resetFilter={resetFilter}
          isModalClass={true}
          closeFilter={() => setIsFilterOpen(false)}
          isFilterOpen={isFilterOpen}
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
        />
      </Modal>
    </div>
  );
};

export default GigAppsPanel;
