import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, SecondaryButton, IconSpinner, IconCard, Modal, SkeletonLoader } from '../../components';
import { FormattedMessage } from 'react-intl';
import css from './OpenAIResearchInstrumentForm.module.css';
import FileView from '../../components/FileView/FileView';
import { handleFileUpload } from '../../util/api';
import { useParams } from 'react-router-dom';
import { fetchQueryReportData } from '../../util/api';
import OptionMenu from './OptionMenu';
import classNames from 'classnames';
import useHandleFileExtensionError from '../../hooks/useHandleFileExtensionError';

const ACCEPT_PROPOSAL_FILE = '.doc, .docx, .pdf, .ppt, .pptx';
const ACCEPT_RESEARCH_INSTRUMENT_FILE = '.doc, .docx, .pdf';
const FILE_SIZE_LIMIT = 25 * 1000 * 1000;

const OpenAIResearchInstrumentForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
          initialText,
          handleRedirectToGigAppsPage,
          manageDisableScrolling,
          showModal, 
          setShowModal,
          appRoute,
          email,
          formStep,
          setFormStep
        } = formRenderProps;

        const params = useParams()

        // const [formStep, setFormStep] = useState(1);
        const [list,setList] = useState([])
        const [proposalDocument, setProposalDocument] = useState(null);
        const [researchDocuments, setResearchDocument] = useState([]);
        const [fileSizeError, setFileSizeError] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [fileLengthError, setFileLengthError] = useState(false);
        const [fileExtensionError, checkForFileExtension] = useHandleFileExtensionError();

        async function handleInputChange(e, index) {
          const modifiedList = list?.length ? [...list] : [];
          modifiedList[index].modifiedItem = e.target.value;
          setList(modifiedList)
          values.updatedList = modifiedList.map(item=>item.modifiedItem)
        }

        const submitDisabled = (formStep === 1 && !proposalDocument) || (formStep === 3 && (researchDocuments.length < 1));

        async function fetchData(){
          const response = await fetchQueryReportData({
            type: appRoute,
            email: email,
            id: params.id
          });
          if(response?.data?.data?.list){
            let fetchedlist =  response?.data?.data?.list.map(item=>{
              return {originalItem:item,modifiedItem:item}
            })
            setList(fetchedlist);
            values.updatedList = response?.data?.data?.list;
          }
        }

        useEffect(() => {
          if (initialText) {
            form.reset();
          }
          if(params && params.id){
            formStep===1 && setFormStep(2)
            fetchData()
            form.change("paramsId", params?.id)
          }
        }, [initialText,params,formStep]);

        async function handleAttachment(file, field) {
          try {
            setIsLoading(true);
            const response = await handleFileUpload('get-parsed-document', { file });
            if (response?.data) {
              if (formStep === 1) {
                setProposalDocument(file);
                form.change(field, response?.data);
              } else {
                values.researchDocuments = values.researchDocuments ? [...values.researchDocuments, {content:response?.data,fileName:file.name}] : [{content:response?.data,fileName:file.name}]
              }
              setIsLoading(false);
              return true
            }
          } catch (error) {
            setIsLoading(false);
          }
        }

        const handleFileSizeError = (message) => {
          setFileSizeError(message);
          setTimeout(() => {
            setFileSizeError(false);
          }, 3000);
        };

        const handleDragStart = (e,index)=>{
          e.dataTransfer.effectAllowed = 'move';
					e.dataTransfer.setData('index', index)
					const dragElement = e.target.firstChild;
					dragElement.style = 'cursor: grabbing';
        }

        const handleDragEnter = (e) => {
					e.currentTarget.style = 'background: rgba(0, 0, 0, 0.2)'
				}

				const handleDragLeave = (e) => {
					e.currentTarget.style = 'background: white'
				}

        const handleDrop = (e, dropIndex) => {
					e.preventDefault();
					const dragIndex = e.dataTransfer.getData('index');
					const newList = [...list];
					const [draggedItem] = newList.splice(dragIndex, 1);
					newList.splice(dropIndex, 0, draggedItem);
					setList(newList);
					e.currentTarget.style = 'background: white'
				};

        return (
          <Form className={formStep !== 2 ? css.researchInstrumentForm : css.editListContainer} onSubmit={handleSubmit}>
            <div className={classNames(css.container,formStep === 2 && css.questionContainer)}>
              <div className={css.generator}>
                <span className={css.generatorTitle}>
                  <FormattedMessage id="OpenAIResearchInstrumentForm.heading" />
                </span>
              </div>
              Step {formStep}
              {formStep === 1 && (
                <div className={css.attachment}>
                  <label>
                    <FormattedMessage id={`OpenAIResearchInstrumentForm.proposalDocumentTitle`} />
                  </label>
                  {proposalDocument ? (
                    <FileView file={proposalDocument}>
                      <span
                        className={css.close}
                        onClick={() => {
                          setProposalDocument(null);
                          form.change('proposalDocument', '');
                        }}
                      >
                        <IconCard brand="cross" />
                      </span>
                    </FileView>
                  ) : (
                    <>
                      <Field
                        id="proposalDocument"
                        name="proposalDocument"
                        accept={ACCEPT_PROPOSAL_FILE}
                        type="file"
                      >
                        {fieldprops => {
                          const { accept, input, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;
                          const onChange =async e => {
                            const file = e.target.files[0];
                            const files = Object.values(e.target.files);
                            const allowedExtensions = /(\.pdf|\.doc|\.docx|\.ppt|\.pptx)$/i
                            let extensionError = checkForFileExtension(allowedExtensions, file)
                            if(extensionError) return
                            if (file && file.name && file.size > FILE_SIZE_LIMIT) {
                              handleFileSizeError(`Please select a file less than ${FILE_SIZE_LIMIT / 1000000}MB size.`);
                              return;
                            }
                            handleAttachment(file, 'proposalDocument');
                            form.change('proposalDocumentFile',file)
                            form.change('gigAppInputFiles',files)
                          };

                          const inputProps = { accept, id: name, name, onChange, type };
                          return (
                            <>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} />
                              )}
                              <label type="button" htmlFor={name} className={css.attachmentLabel}>
                                {isLoading ? (
                                  <IconSpinner strokeColor="#0B96F5" />
                                ) : (
                                  <IconCard brand="upload" />
                                )}
                                <span className={css.dragDrop}>
                                  <FormattedMessage id="GigAppsPage.singleFileUpload" />
                                </span>
                                <span className={css.docType}>
                                  <FormattedMessage id="JobListing.documentAllowList" />
                                </span>
                              </label>
                            </>
                          );
                        }}
                      </Field>
                      {fileSizeError ? (
                        <p className={css.fileSizeError}>
                          {fileSizeError}
                        </p>
                      ) : (
                        ''
                      )}
                      {fileExtensionError && <span className={css.fileSizeError}>Please select files in PDF, DOC, or DOCX format only.</span>}
                    </>
                  )}
                </div>
              )}
              {formStep===2 && (
                <div>
                  {list?.length !== 0 ? (
                    <>
                      <div className={css.questionsHeading}>
                        <h3><FormattedMessage id='OpenAIContentAnalysisForm.generatedHeadingLabel' /></h3>
                        <h3><FormattedMessage id='OpenAIContentAnalysisForm.editableFieldLabel' /></h3>
                      </div>
                      <div className={css.questionsWrapper}>
                        {list?.map((item, index) => {
                          const { originalItem, modifiedItem } = item;
                          return (
                            <div key={index} className={css.row}>
                              <div
                                className={css.left}
                                draggable
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDragOver={(e) => e.preventDefault()}
                                onDragEnter={e => handleDragEnter(e)}
                                onDragLeave={e => handleDragLeave(e)}
                                onDrop={(e) => handleDrop(e, index,)}
                              >
                                <span className={css.dragDots}>
                                  <IconCard brand="dragDots" />
                                </span>
                                <span className={css.count}>{`${index + 1}.`}</span>
                                <div className={css.question}>{originalItem ? originalItem : ""}</div>
                              </div>
                              <textarea
                                onChange={(e) => handleInputChange(e, index)}
                                value={modifiedItem}
                              >
                              </textarea>
                              <OptionMenu
                                form={form}
                                list={list}
                                index={index}
                                setList={setList}
                                manageDisableScrolling={manageDisableScrolling}
                              />
                            </div>
                          )
                        })}
                      </div>
                   </>
                  ) : (
                    <div className={css.loader}>
                      <SkeletonLoader isMobile={true} />
                    </div>
                  )}
                </div>
              )}
              {formStep === 3 && (
                <div className={css.attachment}>
                  <label>
                    <FormattedMessage id={`OpenAIResearchInstrumentForm.researchDocumentTitle`} />
                  </label>
                  {researchDocuments.length < 5 ? (
                    <>
                      <Field
                        id="researchDocuments"
                        name="researchDocuments"
                        accept={ACCEPT_RESEARCH_INSTRUMENT_FILE}
                        type="file"
                      >
                        {fieldprops => {
                          const { accept, input, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;
                          const onChange = async e => {
                            let filesObject = Object.values(e.target.files)

                            const allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;
                            let extensionError = checkForFileExtension(allowedExtensions, filesObject)
                            if(extensionError) return
                            filesObject = filesObject.filter(file => allowedExtensions.test(file.name));
                            if ((filesObject.length + researchDocuments.length) > 5) {
                              setFileLengthError(true)
                            } else {
                              setFileLengthError(false)
                              let filesArray = []
                              for (let i = 0; i < filesObject.length; i++) {
                                if (filesObject[i] && filesObject[i].name && filesObject[i].size > FILE_SIZE_LIMIT) {
                                  handleFileSizeError(`Please select a file less than ${FILE_SIZE_LIMIT / 1000000}MB size.`);
                                  return;
                                }
                                await handleAttachment(filesObject[i], 'researchDocuments')
                                filesArray = [...filesArray, filesObject[i]];
                              }
                              setResearchDocument([...researchDocuments , ...filesArray])
              
                              form.change('gigAppInputFiles',filesObject)
                            }
                          };

                          const inputProps = { accept, id: name, name, onChange, type };
                          return (
                            <>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} multiple />
                              )}
                              <label type="button" htmlFor={name} className={css.attachmentLabel}>
                                {isLoading ? (
                                  <IconSpinner strokeColor="#0B96F5" />
                                ) : (
                                  <IconCard brand="upload" />
                                )}
                                <span className={css.dragDrop}>
                                  <FormattedMessage id="GigAppsPage.singleFileUpload" />
                                </span>
                                <span className={css.docType}>
                                  <FormattedMessage id="OpenAIResearchInstrumentForm.allowedResearchDocumentFormat" />
                                </span>
                              </label>
                            </>
                          );
                        }}
                      </Field>
                      {fileSizeError ? (
                        <p className={css.fileSizeError}>
                          {fileSizeError}
                        </p>
                      ) : (
                        ''
                      )}
                      {fileExtensionError ? (
                        <p className={css.fileSizeError}>
                          Please select files in PDF, DOC, or DOCX format only.
                        </p>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {fileLengthError && <p className={css.fileSizeError}>
                    You can upload maximum of 5 research documents.
                  </p>}
                  {researchDocuments.length > 0 &&
                    researchDocuments.map((item, index) => (
                      <div key={index} className={css.fileViewContainer}>
                        <FileView file={item} >
                          <span
                            className={css.close}
                            onClick={() => {
                              setResearchDocument(researchDocuments.filter((e, i) => i !== index));
                              values.researchDocuments = values.researchDocuments.filter((e, i) => i !== index);
                            }}
                          >
                            <IconCard brand="cross" />
                          </span>
                        </FileView>
                      </div>
                    ))}
                </div>
              )}
              <div className={css.actionButtons}>
                <SecondaryButton
                  type="button"
                  className={css.resetButton}
                  onClick={() => {
                    form.reset()
                    setProposalDocument(null)
                    setResearchDocument(null)
                    setFormStep(1)
                  }}
                  disabled={openaiRequest}
                >
                  <FormattedMessage id="OpenAIResearchInstrumentForm.resetButtonText" />
                </SecondaryButton>
                <PrimaryButton
                  type="submit"
                  className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton )}
                  onClick={(e)=>{
                    if(submitDisabled){
                      e.preventDefault()
                      handleFileSizeError('Please choose a file first')
                    }
                  }}
                  inProgress={openaiRequest}
                >
                  <FormattedMessage
                    id={
                      (formStep === 1 && 'OpenAIResearchInstrumentForm.nextButtonText') ||
                      (formStep === 2 && 'OpenAIResearchInstrumentForm.nextButtonText') ||
                      (formStep === 3 && 'OpenAIResearchInstrumentForm.submitButtonText') ||
                      (!formStep && "Loading...")
                    }
                  />
                </PrimaryButton>
              </div>
            </div>
            <Modal
							id="openSuccessModal"
							className={css.disableModalBorder}
							contentClassName={css.containerClassName}
							isOpen={showModal}
              removeCloseButton={true}
							onClose={() => {
                setShowModal(false)
                handleRedirectToGigAppsPage()
              }}
							usePortal
							onManageDisableScrolling={manageDisableScrolling}
						>
							{formStep===3 && <div className={css.alertModal}>
                <h2>
                  <FormattedMessage id="OpenAiAppsPanel.successModalHeading" />
                </h2>
                <p><FormattedMessage id='OpenAIResearchInstrumentForm.successMessage' /></p>
                <PrimaryButton
                  type='button'
                  className={css.closeButton}
                  onClick={() => {
                    setShowModal(false)
                    handleRedirectToGigAppsPage()
                  }}
                >
                  <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                </PrimaryButton>
              </div>}
              {formStep===1 && 
                <div className={css.alertModal}>
                <h2>
                  <FormattedMessage id="OpenAiAppsPanel.successModalHeading" />
                </h2>
                <p><FormattedMessage id='OpenAIResearchInstrumentForm.proposalProcessingMessage' /></p>
                <PrimaryButton
                  type='button'
                  className={css.closeButton}
                  onClick={() => {
                    setShowModal(false)
                    handleRedirectToGigAppsPage()
                  }}
                >
                  <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                </PrimaryButton>
              </div>
              }
						</Modal>
          </Form>
        );
      }}
    />
  );
};

export default OpenAIResearchInstrumentForm;
