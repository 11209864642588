import { AI_BRIEF_GENERATOR, AI_CONTENT_ANALYSIS, AI_CONTRACT, AI_DISCUSSION_GUIDE, AI_JOB_DESCRIPTION, AI_PERFECT_QUESTION, AI_PROPOSAL, AI_QUERY_REPORT, AI_QUESTIONNAIRE, AI_ANALYZE_QUALITATIVE_DATA, AI_TRANSCRIPTION, AI_SUGGEST_ANSWER_OPTION, AI_PRE_TEXT_PROCESSING, AI_KEYWORD_EXTRACTION, AI_TOPIC_MODELING, AI_SENTIMENT_ANALYSIS, AI_COMBINED_TOPIC_SENTIMENT, AI_RESEARCH_INSTRUMENT_COVERAGE_INDICATOR, AI_ANALYZE_RAW_QUANTITATIVE_DATA, AI_TEXTUAL_CONCEPT_CREATER, AI_RESEARCH_INSTRUMENT_TRANSLATOR, AI_OPEN_ENDED_RESPONSE_TRANSLATOR, AI_TRANSLATE_TRANSCRIPT, AI_EXTRACT_QUESTIONS_FROM_TRANSCRIPTS, AI_ANALYZE_UNSTRUCTURED_DATA } from '../../util/types'
import tempLogo from '../../assets/insightGigTwitter-600x314.jpg';

const devAppsListingIds = { // Developement
  [AI_TRANSCRIPTION]: '65eeb533-8107-4c5f-ac41-2464b6783186',
  [AI_CONTENT_ANALYSIS]: '66d05435-2177-4c38-b427-0088f8ebcd1d',
  [AI_ANALYZE_QUALITATIVE_DATA]: '66826487-1522-4034-af1a-aa51c7c736e1',
  [AI_QUERY_REPORT]: '669e5b09-1fcf-4bae-884c-19bf559bcc22',
  [AI_COMBINED_TOPIC_SENTIMENT]: '66b49b6d-9f5f-4aca-9664-fe7555a8c3c5',
  [AI_ANALYZE_RAW_QUANTITATIVE_DATA]:'66c8407d-2d45-4b9d-97e4-be8f7a6e5a8f',
}
const stagingAppsListingIds = { // Staging
  [AI_TRANSCRIPTION]: '65c6306f-50e1-4353-90e7-434b799fe40d',
  [AI_CONTENT_ANALYSIS]: '65c6312c-669a-45fd-a68b-c8aa7865e7a6',
  [AI_ANALYZE_QUALITATIVE_DATA]: '65c631d5-6bc4-4d5e-b74e-acba3c3f8961',
  [AI_QUERY_REPORT]: '65c63179-7364-440a-85a6-d68065c0e932',
  [AI_COMBINED_TOPIC_SENTIMENT]: '66b61840-353a-47b9-8fb4-f2d4d06513e7',
  [AI_ANALYZE_RAW_QUANTITATIVE_DATA]:'66cda009-7738-487f-837c-ac88f0a7ae45'
}
const prodAppsListingIds = { // Production
  [AI_TRANSCRIPTION]: '662b5bd8-e04b-4b1e-bfb4-9165ab9901c0',
  [AI_CONTENT_ANALYSIS]: '662b53e1-0a63-46e6-9e92-265f9bad94ad',
  [AI_ANALYZE_QUALITATIVE_DATA]: '66f66153-99a8-4ac1-b9e8-612dfc0847b3',
  [AI_QUERY_REPORT]: '66f660f2-b6f1-4698-9614-eeb44dfde948',
  [AI_COMBINED_TOPIC_SENTIMENT]: '66f66127-2722-456e-964e-fde286cad2fc',
  [AI_ANALYZE_RAW_QUANTITATIVE_DATA]: '66f66188-ade5-45fc-8b74-b8d4d0249515',
}
const listingIdObject = process.env.REACT_APP_CANONICAL_ROOT_URL.includes('staging') ? stagingAppsListingIds 
  : process.env.REACT_APP_CANONICAL_ROOT_URL.includes('app')  ? prodAppsListingIds : devAppsListingIds;


export const appsData = [
  {
    name: "Create a Brief", route: AI_BRIEF_GENERATOR, description: 'Create a comprehensive research brief easily using AI.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1701429663/ilothatkz49z4nmwgfd1.jpg',
    tags: ['Brief'],
    category: ['Brief (Requirement) creation'],
    pricing: ['Free for client accounts with paid subscriptions.', 'Free for experts.']
  },
  {
    name: "Create a Questionnaire", route: AI_QUESTIONNAIRE, description: 'Create a quantitative questionnaire faster using AI.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1701429662/fbrkwxnb5rojjbngnj8e.jpg',
    tags: ['Questionnaire', 'Quantitative'],
    category: ['Pre-fieldwork'],
    pricing: ['Free for client accounts with paid subscriptions.', 'Free for experts.']
  },
  {
    name: "Transcribe an Audio or Video file", route: AI_TRANSCRIPTION, description: 'Transcribe an audio or video file in minutes.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1701429663/xkyhayjygbdbxxhbx0bp.jpg',
    tags: ['Transcription', 'Audio', 'Qualitative', 'Video'],
    category: ['Data processing'],
    listingId: listingIdObject[AI_TRANSCRIPTION],
    pricing: ['Free for client accounts with paid subscriptions.', 'Free for experts.']
  },
  {
    name: "Create a Contract", route: AI_CONTRACT, description: 'Generate a contract easily using AI.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1701429663/edc5dhu9bcwsxtj14n4y.jpg',
    tags: ['Contract', 'NDA', 'Procurement', 'Confidentiality'],
    category: ['Contracting'],
    pricing: ['Free for client accounts with paid subscriptions.', 'Free for experts.']
  },
  {
    name: "Create a Proposal", route: AI_PROPOSAL, description: 'Create a research proposal easily using AI.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1701429663/kv0yhw74qaw0qz0zawgq.jpg',
    tags: ['Proposal'],
    category: ['Proposal development'],
    pricing: ['Free for experts.']
  },
  {
    name: "Create a Job Description", route: AI_JOB_DESCRIPTION, description: 'Create a Job Description for any insights role using AI.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1701429663/cv3efutooviyoncm7w0q.jpg',
    tags: ['JD', 'Recruitment'],
    category: ['Job description development'],
    pricing: ['Free for client accounts with paid subscriptions.', 'Free for experts.']
  },
  {
    name: "Content Analysis", route: AI_CONTENT_ANALYSIS, description: 'Analyze the content from a set of interview or FGD transcripts.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1701429664/uai0fcedl5jmvjs4eaae.jpg',
    tags: ['Qualitative', 'Analysis'],
    category: ['Analysis', 'Content Analysis'],
    listingId: listingIdObject[AI_CONTENT_ANALYSIS],
    pricing: ['Free for client accounts with paid subscriptions.', 'Available to free client accounts for a fee.', 'Available to experts for a fee.']
  },
  {
    name: "Discussion Guide Generator", route: AI_DISCUSSION_GUIDE, description: 'Create a qualitative discussion guide faster using AI.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1701429664/zikdwquls2xy6dneeje6.jpg',
    tags: ['Questionnaire', 'Qualitative'],
    category: ['Pre-fieldwork'],
    pricing: ['Free for client accounts with paid subscriptions.', 'Free for experts.']
  },
  {
    name: "Query a Report", route: AI_QUERY_REPORT, description: 'Let the report answer your questions you have of it.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1701429664/yac08cpvhv4tetmduhgd.jpg',
    tags: ['Report', 'Insights', 'Knowledge Management'],
    category: ['Project completed'],
    listingId: listingIdObject[AI_QUERY_REPORT],
    pricing: ['Available to clients for a fee.', 'Available to experts for a fee.']
  },
  {
    name: "Analyze Qualitative Data	", route: AI_ANALYZE_QUALITATIVE_DATA, description: 'Analyze data from a qualitative study, generating insights in minutes.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1701429665/z4quzvlguaa4boexad1i.jpg',
    tags: ['Qualitative', 'Report Writing', 'Analysis'],
    category: ['Reporting'],
    listingId: listingIdObject[AI_ANALYZE_QUALITATIVE_DATA],
    pricing: ['Available to clients for a fee.', 'Available to experts for a fee.']
  },
  {
    name: "Perfect Question", route: AI_PERFECT_QUESTION, description: 'Get alternative options to perfect your market research question.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1703675414/AppIcons/011_Perfect_Question_u0y31c.jpg',
    tags: ['Questionnaire', 'Qualitative', 'Quantitative', 'Wording'],
    category: ['Pre-fieldwork'],
    pricing: ['Free for client accounts with paid subscriptions.', 'Free for experts.']
  },
  {
    name: "Suggest Answer Options", route: AI_SUGGEST_ANSWER_OPTION, description: 'Get comprehensive answer options for quantitative questions.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1703675414/AppIcons/012_Suggest_Answer_Options_vhlpld.jpg',
    tags: ['Questionnaire', 'Quantitative'],
    category: ['Pre-fieldwork'],
    pricing: ['Free for client accounts with paid subscriptions.', 'Free for experts.']
  },
  {
    name: "Text Pre-processing", route: AI_PRE_TEXT_PROCESSING, description: 'Clean open-ended responses given by respondents in a large quantitative survey, before performing analysis.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1707727694/AppIcons/015%20Text%20Pre-processing.jpg',
    tags: ['Quantitative', 'Data Cleaning', 'Open-Ended Questions'],
    category: ['Data processing'],
    // listingId: '65a8efa8-c890-4d5f-bf66-5d5afe43b7ac',//dev
    listingId: '65c631fd-7b08-48fd-a237-c4e6845e56f4',//staging

    pricing: ['Available on a pay-per-use basis to all users. Pricing depends on the number of records for which the analysis needs to be done.',]
  },
  {
    name: "Keyword Extraction Using TFIDF", route: AI_KEYWORD_EXTRACTION, description: 'Identify the most important words in a set of open-ended responses in a large quantitative survey.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1707727694/AppIcons/016%20Keyword%20Extraction%20Using%20TFIDF.jpg',
    tags: ['Quantitative', 'Keywords', 'TFIDF', 'Open-Ended Questions'],
    category: ['Analysis'],
    pricing: ['Available on a pay-per-use basis to all users. Pricing depends on the number of records for which the analysis needs to be done.',]
  },
  {
    name: "Topic Modeling Using NLP", route: AI_TOPIC_MODELING, description: 'Derive the topic(s) of any open-ended answer given by respondents in a large quantitative survey.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1707727695/AppIcons/014%20Topic%20Modeling.jpg',
    tags: ['Quantitative', 'Keywords', 'NLP', 'Open-Ended Questions'],
    category: ['Analysis'],
    pricing: ['Available on a pay-per-use basis to all users. Pricing depends on the number of records for which the analysis needs to be done.',]
  },
  {
    name: "Sentiment Analysis", route: AI_SENTIMENT_ANALYSIS, description: 'Analyse the sentiment of any open-ended answer given by respondents in a large quantitative survey.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1707727695/AppIcons/013%20Sentiment%20Analysis.jpg',
    tags: ['Quantitative', 'Sentiment', 'Open-Ended Questions'],
    category: ['Analysis'],
    pricing: ['Available on a pay-per-use basis to all users. Pricing depends on the number of records for which the analysis needs to be done.',]
  },
  {
    name: "Topic & Sentiment Analysis", route: AI_COMBINED_TOPIC_SENTIMENT, description: 'Derive the topic(s) and analyse the sentiment of open-ended answers in a large quantitative survey.',
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1709626859/AppIcons/017_Combined_Topic_Sentiment.jpg',
    tags: ['Quantitative', 'Sentiment', 'Topic Modeling', 'NLP', 'Open-Ended Questions'],
    category: ['Analysis'],
    pricing: ['Available on a pay-per-use basis to all users. Pricing depends on the number of records for which the analysis needs to be done.',],
    listingId: listingIdObject[AI_COMBINED_TOPIC_SENTIMENT],
  },
  {
    name:"Research Instrument Coverage Indicator", route: AI_RESEARCH_INSTRUMENT_COVERAGE_INDICATOR, description:"Understand if the research instrument(s) cover the objectives in the brief or the proposal.",
    logo:'https://res.cloudinary.com/dt47fzpb5/image/upload/v1713964555/018_Research_Instrument_Coverage_Indicator_utclhy.jpg',
    tags: ['Proposal', 'Questionnaire'],
    category: ['Pre-fieldwork'],
    pricing: ['On-demand usage within limits on paid subscription plans.',]
  },
  {
    name:"Analyze Raw Quantitative Data",route: AI_ANALYZE_RAW_QUANTITATIVE_DATA, description:"Analyze raw data from any quantitative survey, generating insights in minutes.",
    logo:'https://res.cloudinary.com/dt47fzpb5/image/upload/v1715679799/AppIcons/021_Analyze_Raw_Quantitative_Data.jpg',
    tags: ['Quantitative', 'Analysis', 'Insights'],
    category: ['Analysis'],
    listingId: listingIdObject[AI_ANALYZE_RAW_QUANTITATIVE_DATA],
    pricing: ['Available on a pay-per-use basis with assistance from InsightGig. Custom pricing per project depending on number of records for which analysis needs to be done. DIY option available for advanced users.',]
  },
  {
    name:"Concept Creator", route: AI_TEXTUAL_CONCEPT_CREATER, description:"Create a relevant textual concept easily within your word limit.",
    logo:'https://res.cloudinary.com/dt47fzpb5/image/upload/v1715605282/AppIcons/019_Textual%20_Concept_Creator.jpg',
    tags: ['Concept Testing'],
    category: ['Pre-fieldwork'],
    pricing: ['On-demand usage within limits on paid subscription plans.',]
  },
  {
    name:"Research Instrument Translator", route: AI_RESEARCH_INSTRUMENT_TRANSLATOR, description:"Translate your research instrument easily into other languages using this AI based tool.",
    logo:'https://res.cloudinary.com/dt47fzpb5/image/upload/v1716457179/AppIcons/020_Research_Instrument_Translator.jpg',
    tags: ['Questionnaire','Translation'],
    category: ['Pre-fieldwork'],
    pricing: ['On-demand usage within limits on paid subscription plans.',]
  },
  {
    name:"Open-Ended Response Translator", route: AI_OPEN_ENDED_RESPONSE_TRANSLATOR, description:"Translate the open-ended responses in a quantitative survey accurately and efficiently.",
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1720002176/AppIcons/022_Open_Ended_Response_Translator.jpg',
    tags: ['Quantitative', 'Translation', 'Open-Ended Questions'],
    category: ['Data processing'],
    pricing: ['On-demand usage within limits on paid subscription plans.',]
  },
  {
    name:"Translate a Transcript", route: AI_TRANSLATE_TRANSCRIPT, description:"Translate a transcript from a qualitative interview.",
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1720002176/AppIcons/023_Translate_Transcript.jpg',
    tags: ['Qualitative', 'Translation', 'Transcript'],
    category: ['Data processing'],
    pricing: ['On-demand usage within limits on paid subscription plans.',]
  },
  {
    name:"Create a Discussion Guide From Transcripts", route: AI_EXTRACT_QUESTIONS_FROM_TRANSCRIPTS, description:"Create a Discussion Guide from a set of transcripts.",
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1730705749/AppIcons/024_Extract_Questions_from_Transcripts.jpg',
    tags: ['Questionnaire', 'Qualitative'],
    category: ['Analysis'],
    pricing: ['On-demand usage within limits on paid subscription plans.',]
  },
  {
    name:"Analyze Unstructured Information at Scale", route: AI_ANALYZE_UNSTRUCTURED_DATA, description:"Analyze large amount of unstructured information in a spreadsheet with ease.",
    logo: 'https://res.cloudinary.com/dt47fzpb5/image/upload/v1736946999/AppIcons/025_Analyze_Unstructured_Informatio_%20at_Scale.jpg',
    tags: ['Qualitative', 'Report Writing', 'Large Scale', 'Analysis'],
    category: ['Reporting'],
    pricing: ['On-demand usage within limits on paid subscription plans.',]
  },
]

export const macroApps = [
  AI_TRANSCRIPTION,
  AI_ANALYZE_QUALITATIVE_DATA,
  AI_QUERY_REPORT,
  AI_COMBINED_TOPIC_SENTIMENT,
  AI_ANALYZE_RAW_QUANTITATIVE_DATA,
  AI_CONTENT_ANALYSIS,
  // AI_SENTIMENT_ANALYSIS,
  // AI_TOPIC_MODELING,
  // AI_PRE_TEXT_PROCESSING,
  // AI_KEYWORD_EXTRACTION,
]