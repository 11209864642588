import React, { useEffect, useState } from 'react';
import css from './GigAppTransactionPanel.module.css';
import {
  AI_ANALYZE_RAW_QUANTITATIVE_CHAT,
  AI_QUERY_REPORT,
  AI_ANALYZE_QUALITATIVE_DATA,
  CUSTOMER,
  GIG_APPS_TRANSACTION_PAGE_CHAT_TAB,
  GIG_APPS_TRANSACTION_PAGE_GIG_APP_TAB,
  INVITE_STATUS_ACTIVE,
  PROVIDER,
  USER_ROLE_CSM,
} from '../../util/types';
import { GIG_APPS_TRANSACTION_PAGE_OVERVIEW_TAB } from '../../util/types';
import { GIG_APPS_TRANSACTION_PAGE_AI_ASSISTANT_TAB } from '../../util/types';
import { GIG_APPS_TRANSACTION_PAGE_MILESTONE_TAB } from '../../util/types';
import { GIG_APPS_TRANSACTION_PAGE_COLLABORATOR_TAB } from '../../util/types';
import { GIG_APPS_TRANSACTION_PAGE_BOX_TAB } from '../../util/types';
import {
  AIAssistantSection,
  AskAndChatSection,
  BoxFileSection,
  CollaboratorSection,
  MilestoneSection,
  OverviewSection,
} from './GigAppTransactionTabPanel';
import GigAppHomePageSection from './GigAppTransactionTabPanel/GigAppHomePageSection';
import { getUserRole } from '../../util/userRole';
import membersImage from '../../assets/teamicon.png';
import { BorderButton } from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import {
  getCollaboratorMetadata,
  getUserDetails,
  getUserDisplayName,
  getUserFirmInfo,
} from '../../util/destructorHelpers';
import NamedLink from '../NamedLink/NamedLink';
import classNames from 'classnames';
import { getCustomerData } from '../../containers/TransactionPage/TransactionPage.duck';
import IconBannedUser from '../IconBannedUser/IconBannedUser';
import { useDispatch } from 'react-redux';
import IconCard from '../IconCard/IconCard';
import GigAppQueryReportSection from './GigAppTransactionTabPanel/GigAppQueryReportSection';
import { stringContainsOnlyNumbers } from './GigAppTransactionTabPanel/helperFunction';
import MemberCard from './GigAppTransactionTabPanel/MemberCard';
import OpenAIAnalyzeRawQuantitativeDataForm from '../../forms/OpenAIAnalyzeRawQuatitativeDataForm/OpenAIAnalyzeRawQuantitativeDataForm';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { createResourceLocatorString } from '../../util/routes';

const GigAppTransactionPanel = props => {
  const {
    history,
    tab,
    currentUser,
    customer,
    provider,
    currentTransaction,
    notifications,
    intl,
    onManageDisableScrolling,
    socket,
    messages,
    typePartner,
    connectedUsers,
    actionInProgress,
    filteredChatNotifications,
    gigAppInputData,
    location,
    gigAppInputDataInProgress,
    params,
    appName,
  } = props;

  const isTabIncludeNumber = !!tab && stringContainsOnlyNumbers(tab)
  const [userData, setUsersData] = useState([]);
  const [showOnline, setShowOnline] = useState(false);
  const [analyzeFileError, setAnalyzeFileError] = useState();
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
  const dispatch = useDispatch();
  const customerId = customer?.id;
  const providerId = provider?.id;
  const { metadata = {}, lastTransition, processName } =
    (currentTransaction?.id && currentTransaction?.attributes) || {};
  const collaborationMetaData = currentTransaction?.id && (metadata?.collaborationMetaData ?? []);
  const customerEmail = customer?.email;
  const providerEmail = provider?.email;
  const txId = currentTransaction?.id?.uuid;
  const activeEmails =
    collaborationMetaData
      ?.filter(({ status }) => status === INVITE_STATUS_ACTIVE)
      ?.map(({ email }) => email) ?? [];
  const emails = [customerEmail, providerEmail, ...activeEmails];
  const collaborationTransactionDetails = Array.isArray(currentUser?.attributes?.profile?.metadata)
    ? currentUser?.attributes?.profile?.metadata
    : [];
  const isCollaborator = collaborationTransactionDetails.some(tx => tx?.id === txId);

  // Ensure collaborationMetaData is an array before calling map
  const transactionUserIds = [
    customerId,
    providerId,
    ...(Array.isArray(collaborationMetaData)
      ? collaborationMetaData.map(collaborator => collaborator.collaboratorID)
      : []),
  ];

  // Create an object to store online status for each user ID
  const isOnline = {};

  // Iterate through connectedUsers and check if each userId is in transactionUserIds
  for (const userId in connectedUsers) {
    if (transactionUserIds.includes(userId)) {
      isOnline[userId] = true;
    } else {
      isOnline[userId] = false;
    }
  }

  const boxFolder = metadata?.boxFolder;

  const typeUser = currentUser?.id && getUserRole(currentUser);
  const collaborationData =
    (currentTransaction?.id && getCollaboratorMetadata({ currentTransaction })) || [];
  const membersData = [
    {
      ...customer,
      name: getUserDisplayName(customer?.fullName),
      fullName: customer?.fullName,
      role: CUSTOMER,
    },
    {
      ...provider,
      name: getUserDisplayName(provider?.fullName),
      fullName: provider?.fullName,
      role: PROVIDER,
    },
    ...collaborationData,
  ];
  const currentUserEmail = currentUser?.id && getUserDetails(currentUser)?.email;

  const emailParams = {
    userEmail: emails.filter(email => email != currentUserEmail),
    providerName: currentUser?.id && currentUser?.attributes?.profile?.firstName,
    currentPath: typeof window !== 'undefined' && window.location.href,
    lastTransition,
  };
  useEffect(() => {
    tab === GIG_APPS_TRANSACTION_PAGE_CHAT_TAB &&
      membersData.forEach(member => {
        member?.email &&
          dispatch(getCustomerData(member.email))
            .then(user => {
              setUsersData(oldArray => [...oldArray, user]);
            })
            .catch(error => console.log(error));
      });
  }, []);

  const handleTransactionTabs = () => {
    switch (tab) {
      case GIG_APPS_TRANSACTION_PAGE_GIG_APP_TAB:
        return (
          <div className={css.descriptionDetail}>
            <GigAppHomePageSection
              currentTransaction={currentTransaction}
              intl={intl}
              customer={customer}
              provider={provider}
              activeTab={tab}
              currentUser={currentUser}
              boxFolder={boxFolder}
              onManageDisableScrolling={onManageDisableScrolling}
              metadata={metadata}
              gigAppInputData={gigAppInputData}
              history={history}
              gigAppInputDataInProgress={gigAppInputDataInProgress}
              currentUserEmail={currentUserEmail}
              appName={appName}
            />
          </div>
        );
      case GIG_APPS_TRANSACTION_PAGE_OVERVIEW_TAB:
        return (
          <div className={css.descriptionDetail}>
            <OverviewSection currentTransaction={currentTransaction} />
          </div>
        );
      case GIG_APPS_TRANSACTION_PAGE_CHAT_TAB:
        return (
          <div>
            <div className={css.mobileOnlineBox} onClick={() => setShowOnline(!showOnline)}>
              <IconCard brand="profileicon" />
            </div>
            <div className={css.chatDetail}>
              {showOnline && isMobile ? null : (
                <AskAndChatSection
                  currentTransaction={currentTransaction}
                  intl={intl}
                  customer={customer}
                  provider={provider}
                  activeTab={tab}
                  currentUser={currentUser}
                  boxFolder={boxFolder}
                  onManageDisableScrolling={onManageDisableScrolling}
                  socket={socket}
                  messages={messages}
                  connectedUsers={connectedUsers}
                  className={css.chatSection}
                  isOnline={isOnline}
                />
              )}
              <div className={!showOnline && isMobile ? css.rightWrapper : css.mobileRightWrapper}>
                <div className={classNames(css.chatMembers, true && css.chatMembersMobile)}>
                  <h3>
                    <FormattedMessage id="TransactionPanel.members" />
                  </h3>
                  {membersData?.length !== 0 && membersData?.map((member, index) => (
                    <MemberCard member={member} index={index} userData={userData} connectedUsers={connectedUsers}/>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      case GIG_APPS_TRANSACTION_PAGE_AI_ASSISTANT_TAB:
        return (
          <div className={css.descriptionDetail}>
            <AIAssistantSection />
          </div>
        );
      case GIG_APPS_TRANSACTION_PAGE_MILESTONE_TAB:
        return (
          <div className={css.descriptionDetail}>
            <ul>
              <MilestoneSection
                typeUser={typeUser}
                transaction={currentTransaction}
                intl={intl}
                actionInProgress={actionInProgress}
                currentUser={currentUser}
                onManageDisableScrolling={onManageDisableScrolling}
                collaborationMetaData={collaborationMetaData}
                emails={emails}
                isCollaborator={isCollaborator}
                txId={txId}
                emailParams={emailParams}
              />
            </ul>
          </div>
        );
      case GIG_APPS_TRANSACTION_PAGE_COLLABORATOR_TAB:
        return (
          <div className={css.descriptionDetail}>
            <CollaboratorSection
              currentTransaction={currentTransaction}
              connectedUsers={connectedUsers}
              lastTransition={lastTransition}
              isOnline={isOnline}
              typePartner={typePartner}
              processName={processName}
            />
          </div>
        );
      case GIG_APPS_TRANSACTION_PAGE_BOX_TAB:
        return (
          <div className={css.descriptionDetail}>
            <BoxFileSection
              metadata={metadata}
              location={location}
              gigAppInputData={gigAppInputData}
            />
          </div>
        );
      case AI_QUERY_REPORT:
      case AI_ANALYZE_QUALITATIVE_DATA:
        return (
          <div className={css.descriptionDetail}>
            <GigAppQueryReportSection
              params={params}
              currentUser={currentUser}
              txId={txId}
              history={history}
            />
          </div>
        );
      case AI_ANALYZE_RAW_QUANTITATIVE_CHAT:
        return (
          <div className={css.descriptionDetail}>
            {(Array.isArray(gigAppInputData) && gigAppInputData.length>0) && 
              <OpenAIAnalyzeRawQuantitativeDataForm
                initialValues={{step: 5}}
                appData={{id : gigAppInputData.find((item)=> item?.mongoEntry?.data?.threadId === params.fileId)?.mongoEntry?._id}}
                appTitle={appName}
                analyzeFileError={analyzeFileError}
                setAnalyzeFileError={setAnalyzeFileError}
                handleRedirectToGigAppsPage={() => {
                  history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
                }}
                onSubmit={(values, form)=> {}}
                manageDisableScrolling={(componentId, disableScrolling) =>
                  dispatch(manageDisableScrolling(componentId, disableScrolling))
                }
              />
            }
          </div>
        )
    }
  };
  return <div className={css.gigAppTransactionPanel}>{handleTransactionTabs()}</div>;
};

export default GigAppTransactionPanel;
